import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {Observable, Subscription} from "rxjs";
import {siteKey} from '../../../app-config/app.recap';
import {LangService} from "../../../shared/services/lang.service";
import {BsModalService, BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {AppointmentService} from "../../services/appointment.service";
import {Verification} from "../../model/verfication";
import {Subject} from "../../model/subject";
import {Address} from "../../model/address";
import {Applicant} from "../../model/applicant";
import {UserSched} from "../../model/userSched";
import {BirthInfo} from "../../model/birthInfo";
import {Contact} from "../../model/contact";
import {UserInfos} from "../../model/userInfos";
import {RedirectService} from "../../services/redirect.service";
import {CalendarEvent} from "angular-calendar";
import {timer} from 'rxjs';
import {async} from "@angular/core/testing";
import {Country} from "../../../_models/country";
import CountryJson from "../../../../assets/data/country.json";
import { SearchCountryField, PhoneNumberFormat, CountryISO } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modalHelp') modalHelp;
  @ViewChild('formGenInfo') formGenInfo;
  @ViewChild('errorModal', {static: false}) errorModal: ModalDirective;
  @ViewChild('errorUserModal', {static: false}) errorUserModal: ModalDirective;
  @ViewChild('modalCond', {static: true}) modalCond: ModalDirective;
  @ViewChild('modalCidNum', {static: true}) modalCidNum: ModalDirective;
  @ViewChild('confirmModal', {static: true}) confirmModal: ModalDirective;


  errorUser: Boolean = false;
  errMessage: string;
  accountError: string;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };

  currentDate = new Date(Date.now());
  latest_date;
  langue = this.langService.selectedLang;
  siteKey: any = siteKey;
  subscription: Subscription;
  spinner: Boolean = true;
  _spinner: Boolean = true;
  btn_send: Boolean = false;
  croppedImage: any = '';
  respErrCode: Boolean = false;
  myFileName = 'formulaire-demande-passeport.pdf';
  fileUrl = 'assets/formulaire-demande-passeport.pdf';

  imgeWidth;
  show: Boolean = false;
  col_show_1: Boolean = false;
  col_show_2: Boolean = false;
  col_show_3: Boolean = false;

  user_informations = {
    email: '',
    code: ''
  };
  countries = [
    {
      "code": "AF",
      "name": "AFGHANISTAN"
    },
    {
      "code": "ZA",
      "name": "AFRIQUE DU SUD"
    },
    {
      "code": "AL",
      "name": "ALBANIE"
    },
    {
      "code": "DZ",
      "name": "ALGERIE"
    },
    {
      "code": "DE",
      "name": "ALLEMAGNE"
    },
    {
      "code": "AD",
      "name": "ANDORRE"
    },
    {
      "code": "AO",
      "name": "ANGOLA"
    },
    {
      "code": "AI",
      "name": "ANGUILLA"
    },
    {
      "code": "AQ",
      "name": "ANTARCTIQUE"
    },
    {
      "code": "AG",
      "name": "ANTIGUA-ET-BARBUDA"
    },
    {
      "code": "AN",
      "name": "ANTILLES NEERLANDAISES"
    },
    {
      "code": "SA",
      "name": "ARABIE SAOUDITE"
    },
    {
      "code": "AR",
      "name": "ARGENTINE"
    },
    {
      "code": "AM",
      "name": "ARMENIE"
    },
    {
      "code": "AW",
      "name": "ARUBA"
    },
    {
      "code": "AU",
      "name": "AUSTRALIE"
    },
    {
      "code": "AT",
      "name": "AUTRICHE"
    },
    {
      "code": "AZ",
      "name": "AZERBAIDJAN"
    },
    {
      "code": "BS",
      "name": "BAHAMAS"
    },
    {
      "code": "BH",
      "name": "BAHREIN"
    },
    {
      "code": "BD",
      "name": "BANGLADESH"
    },
    {
      "code": "BB",
      "name": "BARBADE"
    },
    {
      "code": "PW",
      "name": "BELAU"
    },
    {
      "code": "BE",
      "name": "BELGIQUE"
    },
    {
      "code": "BZ",
      "name": "BELIZE"
    },
    {
      "code": "BJ",
      "name": "BENIN"
    },
    {
      "code": "BM",
      "name": "BERMUDES"
    },
    {
      "code": "BT",
      "name": "BHOUTAN"
    },
    {
      "code": "BY",
      "name": "BIELORUSSIE"
    },
    {
      "code": "MM",
      "name": "BIRMANIE"
    },
    {
      "code": "BO",
      "name": "BOLIVIE"
    },
    {
      "code": "BA",
      "name": "BOSNIE-HERZEGOVINE"
    },
    {
      "code": "BW",
      "name": "BOTSWANA"
    },
    {
      "code": "BR",
      "name": "BRESIL"
    },
    {
      "code": "BN",
      "name": "BRUNEI"
    },
    {
      "code": "BG",
      "name": "BULGARIE"
    },
    {
      "code": "BF",
      "name": "BURKINA FASO"
    },
    {
      "code": "BI",
      "name": "BURUNDI"
    },
    {
      "code": "KH",
      "name": "CAMBODGE"
    },
    {
      "code": "CM",
      "name": "CAMEROUN"
    },
    {
      "code": "CA",
      "name": "CANADA"
    },
    {
      "code": "CV",
      "name": "CAP-VERT"
    },
    {
      "code": "CL",
      "name": "CHILI"
    },
    {
      "code": "CN",
      "name": "CHINE"
    },
    {
      "code": "CY",
      "name": "CHYPRE"
    },
    {
      "code": "CO",
      "name": "COLOMBIE"
    },
    {
      "code": "KM",
      "name": "COMORES"
    },
    {
      "code": "CG",
      "name": "CONGO"
    },
    {
      "code": "KP",
      "name": "COREE DU NORD"
    },
    {
      "code": "KR",
      "name": "COREE DU SUD"
    },
    {
      "code": "CR",
      "name": "COSTA RICA"
    },
    {
      "code": "CI",
      "name": "COTE D'IVOIRE"
    },
    {
      "code": "HR",
      "name": "CROATIE"
    },
    {
      "code": "CU",
      "name": "CUBA"
    },
    {
      "code": "DK",
      "name": "DANEMARK"
    },
    {
      "code": "DJ",
      "name": "DJIBOUTI"
    },
    {
      "code": "DM",
      "name": "DOMINIQUE"
    },
    {
      "code": "EG",
      "name": "EGYPTE"
    },
    {
      "code": "AE",
      "name": "EMIRATS ARABES UNIS"
    },
    {
      "code": "EC",
      "name": "EQUATEUR"
    },
    {
      "code": "ER",
      "name": "ERYTHREE"
    },
    {
      "code": "ES",
      "name": "ESPAGNE"
    },
    {
      "code": "EE",
      "name": "ESTONIE"
    },
    {
      "code": "US",
      "name": "ETATS-UNIS"
    },
    {
      "code": "ET",
      "name": "ETHIOPIE"
    },
    {
      "code": "FI",
      "name": "FINLANDE"
    },
    {
      "code": "FR",
      "name": "FRANCE"
    },
    {
      "code": "GA",
      "name": "GABON"
    },
    {
      "code": "GM",
      "name": "GAMBIE"
    },
    {
      "code": "GE",
      "name": "GEORGIE"
    },
    {
      "code": "GH",
      "name": "GHANA"
    },
    {
      "code": "GI",
      "name": "GIBRALTAR"
    },
    {
      "code": "GR",
      "name": "GRECE"
    },
    {
      "code": "GD",
      "name": "GRENADE"
    },
    {
      "code": "GL",
      "name": "GROENLAND"
    },
    {
      "code": "GU",
      "name": "GUAM"
    },
    {
      "code": "GT",
      "name": "GUATEMALA"
    },
    {
      "code": "GN",
      "name": "GUINEE"
    },
    {
      "code": "GQ",
      "name": "GUINEE EQUATORIALE"
    },
    {
      "code": "GW",
      "name": "GUINEE-BISSAU"
    },
    {
      "code": "GY",
      "name": "GUYANA"
    },
    {
      "code": "GF",
      "name": "GUYANE FRANÇAISE"
    },
    {
      "code": "HT",
      "name": "HAITI"
    },
    {
      "code": "HN",
      "name": "HONDURAS"
    },
    {
      "code": "HK",
      "name": "HONG KONG"
    },
    {
      "code": "HU",
      "name": "HONGRIE"
    },
    {
      "code": "BV",
      "name": "ILE BOUVET"
    },
    {
      "code": "CX",
      "name": "ILE CHRISTMAS"
    },
    {
      "code": "NF",
      "name": "ILE NORFOLK"
    },
    {
      "code": "KY",
      "name": "ILES CAYMAN"
    },
    {
      "code": "CK",
      "name": "ILES COOK"
    },
    {
      "code": "CC",
      "name": "ILES DES COCOS"
    },
    {
      "code": "FO",
      "name": "ILES FEROE"
    },
    {
      "code": "FJ",
      "name": "ILES FIDJI"
    },
    {
      "code": "GS",
      "name": "ILES GEORGIE DU SUD ET SANDWICH DU SUD"
    },
    {
      "code": "HM",
      "name": "ILES HEARD ET MCDONALD"
    },
    {
      "code": "FK",
      "name": "ILES MALOUINES"
    },
    {
      "code": "MH",
      "name": "ILES MARSHALL"
    },
    {
      "code": "UM",
      "name": "ILES MINEURES ELOIGNEES DES ETATS-UNIS"
    },
    {
      "code": "PN",
      "name": "ILES PITCAIRN"
    },
    {
      "code": "SB",
      "name": "ILES SALOMON"
    },
    {
      "code": "SJ",
      "name": "ILES SVALBARD ET JAN MAYEN"
    },
    {
      "code": "TC",
      "name": "ILES TURKS-ET-CAICOS"
    },
    {
      "code": "VI",
      "name": "ILES VIERGES AMERICAINES"
    },
    {
      "code": "VG",
      "name": "ILES VIERGES BRITANNIQUES"
    },
    {
      "code": "IN",
      "name": "INDE"
    },
    {
      "code": "ID",
      "name": "INDONESIE"
    },
    {
      "code": "IR",
      "name": "IRAN"
    },
    {
      "code": "IQ",
      "name": "IRAQ"
    },
    {
      "code": "IE",
      "name": "IRLANDE"
    },
    {
      "code": "IS",
      "name": "ISLANDE"
    },
    {
      "code": "IL",
      "name": "ISRAEL"
    },
    {
      "code": "IT",
      "name": "ITALIE"
    },
    {
      "code": "JM",
      "name": "JAMAIQUE"
    },
    {
      "code": "JP",
      "name": "JAPON"
    },
    {
      "code": "JO",
      "name": "JORDANIE"
    },
    {
      "code": "KZ",
      "name": "KAZAKHSTAN"
    },
    {
      "code": "KE",
      "name": "KENYA"
    },
    {
      "code": "KG",
      "name": "KIRGHIZISTAN"
    },
    {
      "code": "KI",
      "name": "KIRIBATI"
    },
    {
      "code": "KW",
      "name": "KOWEIT"
    },
    {
      "code": "RE",
      "name": "LA REUNION"
    },
    {
      "code": "LA",
      "name": "LAOS"
    },
    {
      "code": "LS",
      "name": "LESOTHO"
    },
    {
      "code": "LV",
      "name": "LETTONIE"
    },
    {
      "code": "LB",
      "name": "LIBAN"
    },
    {
      "code": "LR",
      "name": "LIBERIA"
    },
    {
      "code": "LY",
      "name": "LIBYE"
    },
    {
      "code": "LI",
      "name": "LIECHTENSTEIN"
    },
    {
      "code": "LT",
      "name": "LITUANIE"
    },
    {
      "code": "LU",
      "name": "LUXEMBOURG"
    },
    {
      "code": "MO",
      "name": "MACAO"
    },
    {
      "code": "MK",
      "name": "MACEDOINE"
    },
    {
      "code": "MG",
      "name": "MADAGASCAR"
    },
    {
      "code": "MY",
      "name": "MALAISIE"
    },
    {
      "code": "MW",
      "name": "MALAWI"
    },
    {
      "code": "MV",
      "name": "MALDIVES"
    },
    {
      "code": "ML",
      "name": "MALI"
    },
    {
      "code": "MT",
      "name": "MALTE"
    },
    {
      "code": "MP",
      "name": "MARIANNES DU NORD"
    },
    {
      "code": "MA",
      "name": "MAROC"
    },
    {
      "code": "MQ",
      "name": "MARTINIQUE"
    },
    {
      "code": "MU",
      "name": "MAURICE"
    },
    {
      "code": "MR",
      "name": "MAURITANIE"
    },
    {
      "code": "YT",
      "name": "MAYOTTE"
    },
    {
      "code": "MX",
      "name": "MEXIQUE"
    },
    {
      "code": "FM",
      "name": "MICRONESIE"
    },
    {
      "code": "MD",
      "name": "MOLDAVIE"
    },
    {
      "code": "MC",
      "name": "MONACO"
    },
    {
      "code": "MN",
      "name": "MONGOLIE"
    },
    {
      "code": "ME",
      "name": "MONTENEGRO"
    },
    {
      "code": "MS",
      "name": "MONTSERRAT"
    },
    {
      "code": "MZ",
      "name": "MOZAMBIQUE"
    },
    {
      "code": "NA",
      "name": "NAMIBIE"
    },
    {
      "code": "NR",
      "name": "NAURU"
    },
    {
      "code": "NP",
      "name": "NEPAL"
    },
    {
      "code": "NI",
      "name": "NICARAGUA"
    },
    {
      "code": "NE",
      "name": "NIGER"
    },
    {
      "code": "NG",
      "name": "NIGERIA"
    },
    {
      "code": "NU",
      "name": "NIOUE"
    },
    {
      "code": "NO",
      "name": "NORVEGE"
    },
    {
      "code": "NC",
      "name": "NOUVELLE-CALEDONIE"
    },
    {
      "code": "NZ",
      "name": "NOUVELLE-ZELANDE"
    },
    {
      "code": "OM",
      "name": "OMAN"
    },
    {
      "code": "UG",
      "name": "OUGANDA"
    },
    {
      "code": "UZ",
      "name": "OUZBEKISTAN"
    },
    {
      "code": "PK",
      "name": "PAKISTAN"
    },
    {
      "code": "PS",
      "name": "PALESTINE"
    },
    {
      "code": "PA",
      "name": "PANAMA"
    },
    {
      "code": "PG",
      "name": "PAPOUASIE-NOUVELLE-GUINEE"
    },
    {
      "code": "PY",
      "name": "PARAGUAY"
    },
    {
      "code": "NL",
      "name": "PAYS-BAS"
    },
    {
      "code": "PE",
      "name": "PEROU"
    },
    {
      "code": "PH",
      "name": "PHILIPPINES"
    },
    {
      "code": "PL",
      "name": "POLOGNE"
    },
    {
      "code": "PF",
      "name": "POLYNESIE FRANÇAISE"
    },
    {
      "code": "PR",
      "name": "PORTO RICO"
    },
    {
      "code": "PT",
      "name": "PORTUGAL"
    },
    {
      "code": "QA",
      "name": "QATAR"
    },
    {
      "code": "CF",
      "name": "REPUBLIQUE CENTRAFRICAINE"
    },
    {
      "code": "CD",
      "name": "REPUBLIQUE DEMOCRATIQUE DU CONGO"
    },
    {
      "code": "DO",
      "name": "REPUBLIQUE DOMINICAINE"
    },
    {
      "code": "CZ",
      "name": "REPUBLIQUE TCHEQUE"
    },
    {
      "code": "RO",
      "name": "ROUMANIE"
    },
    {
      "code": "GB",
      "name": "ROYAUME-UNI"
    },
    {
      "code": "RU",
      "name": "RUSSIE"
    },
    {
      "code": "RW",
      "name": "RWANDA"
    },
    {
      "code": "EH",
      "name": "SAHARA OCCIDENTAL"
    },
    {
      "code": "BL",
      "name": "SAINT-BARTHELEMY"
    },
    {
      "code": "KN",
      "name": "SAINT-CHRISTOPHE-ET-NIEVES"
    },
    {
      "code": "SM",
      "name": "SAINT-MARIN"
    },
    {
      "code": "PM",
      "name": "SAINT-PIERRE-ET-MIQUELON"
    },
    {
      "code": "VA",
      "name": "SAINT-SIEGE"
    },
    {
      "code": "VC",
      "name": "SAINT-VINCENT-ET-LES-GRENADINES"
    },
    {
      "code": "SH",
      "name": "SAINTE-HELENE"
    },
    {
      "code": "LC",
      "name": "SAINTE-LUCIE"
    },
    {
      "code": "SV",
      "name": "SALVADOR"
    },
    {
      "code": "WS",
      "name": "SAMOA"
    },
    {
      "code": "AS",
      "name": "SAMOA AMERICAINES"
    },
    {
      "code": "ST",
      "name": "SAO TOME-ET-PRINCIPE"
    },
    {
      "code": "SN",
      "name": "SENEGAL"
    },
    {
      "code": "RS",
      "name": "SERBIE"
    },
    {
      "code": "SC",
      "name": "SEYCHELLES"
    },
    {
      "code": "SL",
      "name": "SIERRA LEONE"
    },
    {
      "code": "SG",
      "name": "SINGAPOUR"
    },
    {
      "code": "SK",
      "name": "SLOVAQUIE"
    },
    {
      "code": "SI",
      "name": "SLOVENIE"
    },
    {
      "code": "SO",
      "name": "SOMALIE"
    },
    {
      "code": "SD",
      "name": "SOUDAN"
    },
    {
      "code": "SS",
      "name": "SOUDAN DU SUD"
    },
    {
      "code": "LK",
      "name": "SRI LANKA"
    },
    {
      "code": "SE",
      "name": "SUEDE"
    },
    {
      "code": "CH",
      "name": "SUISSE"
    },
    {
      "code": "SR",
      "name": "SURINAME"
    },
    {
      "code": "SZ",
      "name": "SWAZILAND"
    },
    {
      "code": "SY",
      "name": "SYRIE"
    },
    {
      "code": "TJ",
      "name": "TADJIKISTAN"
    },
    {
      "code": "TW",
      "name": "TAIWAN"
    },
    {
      "code": "TZ",
      "name": "TANZANIE"
    },
    {
      "code": "TD",
      "name": "TCHAD"
    },
    {
      "code": "TF",
      "name": "TERRES AUSTRALES FRANÇAISES"
    },
    {
      "code": "IO",
      "name": "TERRITOIRE BRITANNIQUE DE L'OCEAN INDIEN"
    },
    {
      "code": "TH",
      "name": "THAILANDE"
    },
    {
      "code": "TL",
      "name": "TIMOR ORIENTAL"
    },
    {
      "code": "TG",
      "name": "TOGO"
    },
    {
      "code": "TK",
      "name": "TOKELAOU"
    },
    {
      "code": "TO",
      "name": "TONGA"
    },
    {
      "code": "TT",
      "name": "TRINITE-ET-TOBAGO"
    },
    {
      "code": "TN",
      "name": "TUNISIE"
    },
    {
      "code": "TM",
      "name": "TURKMENISTAN"
    },
    {
      "code": "TR",
      "name": "TURQUIE"
    },
    {
      "code": "TV",
      "name": "TUVALU"
    },
    {
      "code": "UA",
      "name": "UKRAINE"
    },
    {
      "code": "UY",
      "name": "URUGUAY"
    },
    {
      "code": "VU",
      "name": "VANUATU"
    },
    {
      "code": "VE",
      "name": "VENEZUELA"
    },
    {
      "code": "VN",
      "name": "VIET NAM"
    },
    {
      "code": "WF",
      "name": "WALLIS-ET-FUTUNA"
    },
    {
      "code": "YE",
      "name": "YEMEN"
    },
    {
      "code": "ZM",
      "name": "ZAMBIE"
    },
    {
      "code": "ZW",
      "name": "ZIMBABWE"
    }
  ];
  resCountries: Country[] = CountryJson;
  _country: any = [];
  frCountries = [
    {
      "code": "FR",
      "name": "FRANCE"
    }, {
      "code": "IL",
      "name": "ISRAEL"
    }, {
      "code": "PT",
      "name": "PORTUGAL"
    }];

  spainCountries = [
    {
      "code": "ES",
      "name": "ESPAGNE"
    }, {
      "code": "MT",
      "name": "MALTE"
    }];
  verificationInf: Verification;
  subject: Subject;
  ekaidiRecaptcha: any = null;
  myRecaptcha: Boolean = true;
  max = 5;
  dynamic = 1;
  type: string;
  value = 1;
  title = 't1';
  nextStep: Boolean = true;
  hidePrevious: Boolean = false;
  linkType = 'EMAIL';
  i = 1;
  col_7 = 'col-xl-8';
  col_12 = 'col-xl-11';
  prev_hand = false;
  email_req = false;
  showAlert = false;
  profileCreated = false;
  count = 5;
  durationfive = 5;
  durationten = 10;
  locale: string = this.langService.selectedLang;
  birthDate: any;
  test;

  /* steps workflow */
  _verification: Boolean = true;
  verification: Boolean = false;
  userInfo: Boolean = false;
  isCalendar: Boolean = false;
  recapitulatif: Boolean = false;
  /* end workflow */

  address: Address;
  applicant: Applicant;
  userSched: UserSched;
  birthInfo: BirthInfo;
  contact: Contact;
  userInfos: UserInfos;
  agency: string;
  dossiers: UserSched[];
  dates: any [] = [];
  private dateEvent: any;
  private hourStart: string;
  private hourEnd: string;
  paye: any = true;
  messageError: any;
  messageError1: any;
  justifAccept: Boolean = false;
  private cancel: boolean = true;
  benifList: boolean = false;
  private timer: Observable<any>;
  private recapError: boolean = false;
  private spinerCal: boolean = true;
  rdvExist: any = false;
  rescountry = '';
  private agencies: any [] = [];

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  onlyCountries: any[] = [];
  hasPatternError:boolean = false;
  selectedCountry: Country;

  constructor(private router: Router,
              private datePipe: DatePipe,
              private appointmentService: AppointmentService,
              private langService: LangService,
              private modalService: BsModalService,
              private redirectService: RedirectService) {
    this.currentDate = new Date();
    this.latest_date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
  }

  ngOnInit() {
    this.getAgency();
    if (this.value === 1) {
      this.hidePrevious = true;
    }
    // this.agency = 'KDI201800000001';
    this.dossiers = [];
    this.address = new Address();
    this.birthInfo = new BirthInfo();
    this.applicant = new Applicant();
    this.userSched = new UserSched();
    this.contact = new Contact();
    this.userInfos = new UserInfos();
  }

  checkPhoneNumber(event: any) {
    if (event) {
      const phoneNumber = event;
      if (this.selectedCountry.formats?.phoneNumberFormat?.require && this.selectedCountry.formats?.phoneNumberFormat?.regex) {
        let phNumber = phoneNumber.number.toString().replaceAll(' ', '');
        this.hasPatternError = !phNumber.match(this.selectedCountry.formats.phoneNumberFormat.regex);
      } else {
        this.hasPatternError = false;
      }
    }
  }

  handleResidenceChange(event: any) {
    this.selectedCountry = this._country.filter(country => country.code === event)[0];
  //console.log("Selected Country => ", this.selectedCountry);
  }

  hasRequiredPhone() {
    if (!this.contact.mobile && this.selectedCountry.formats?.phoneNumberFormat?.require) {
      return true;
    }
    return false;
  }

  openModalHelp(template: TemplateRef<any>) {
    if (this.recapitulatif || this.isCalendar || this.userInfo) {
      this.modalRef = this.modalService.show(template, this.config);
    }
  }

  checkCheckbox(event) {
    if (this.justifAccept) {
      this.modalCond.show();
    }
  }

  hideConfirmModal() {
    this.confirmModal.hide();
  }

  showModal(): void {
    this.errorModal.show();
  }

  hidemMdalCidNum() {
    this.modalCidNum.hide();
  }

  showMdalCidNum(): void {
    this.modalCidNum.show();
  }

  hideModal(): void {
    this.errorModal.hide();
  }

  hideUserModal(): void {
    this.errorUserModal.hide();
  }

  hideConModal(): void {
    this.modalCond.hide();
  }

  onRedirectHome() {
    this.router.navigateByUrl('home');
  }

  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  onReceiveCode() {
    this.myRecaptcha = true;
    this.spinner = false;
    this.prev_hand = false;
    this.subscription = this.appointmentService
      .verifyEmail(this.linkType, this.user_informations.email, this.user_informations.email, this.ekaidiRecaptcha)
      .subscribe(
        (resp: any) => {
        //console.log('response => ', resp);
          if (resp.data.valid) {
            // email validé déja
            this.btn_send = false;
            this.incrementStep();
            this.incrementStep();
            this.title = 't4';
            this.userInfo = true;
            this.contact.email = this.user_informations.email;
            this.hidePrevious = false;
            this._verification = false;
            this.prev_hand = true;
            this.accountError = 'email_valid';
            this.nextStep = false;
          } else {
            this.sendCode();
          }
        },
        (error) => {
          this.spinner = true;
          // code non envoyé, envoyer code et passer à étape 2 : validation de code
          if (error.status === 404) {
            this.sendCode();
          } else if (error.status === 409) {
            this.setTimerRecap(5000);
          }
        //console.log('error => ', error);
        },
        () => {
          this.spinner = true;
          // console.log('Observable complete!');
        }
      );
  }

  public setTimer(time) {
    this.showAlert = true;
    this.timer = timer(time);
    this.subscription = this.timer.subscribe(() => {
      this.showAlert = false;
    });
  }

  public setTimerRecap(time) {
    this.recapError = true;
    this.timer = timer(time);
    this.subscription = this.timer.subscribe(() => {
      this.recapError = false;
    });
  }

  sendCode() {
    this.verificationInf = new Verification();
    this.subject = new Subject();
    this.verificationInf.type = this.linkType;
    this.subject.contact = this.user_informations.email;
    this.subject.discriminator = this.user_informations.email;
    this.verificationInf.subject = this.subject;
    this.subscription = this.appointmentService.generateCode(this.verificationInf).subscribe(
      (resp) => {
        this.btn_send = true;
        this.nextStep = false;
        this.nextVerification2();
        this.showAlert = true;
      },
      (error) => {
      //console.log('error sendCode => ', error);
      },
      () => {
      //console.log('complete => *');
        this.spinner = true;
      }
    );
  }

  getAgency() {
    this.subscription = this.appointmentService.getAgency().subscribe(
      (resp: any) => {
      //console.log(resp);
        this.agencies = resp.data;
      },
      (error) => {
      //console.log('error sendCode => ', error);
      },
      () => {
      //console.log('complete => *');
      }
    );
  }

  chooseAppoin() {
    this._spinner = false;
    this.userInfos.dossiers.forEach(folder => {
      delete folder.isCollapsed;
    });
    this.subscription = this.appointmentService.contactData(this.userInfos).subscribe(
      (response: any) => {
      //console.log('response => ', response);
        try {
          const mercanet = response.data.mercanet;
          if (mercanet != null) {
            const data = {
              Data: mercanet.data,
              InterfaceVersion: mercanet.interfaceVersion,
              Seal: mercanet.seal,
              SealAlgorithm: mercanet.algorithm,
              Encode: mercanet.encode
            };
            this.redirectService.post(data, response.data.mercanet.redirect);
          } else {
            this.navigateToHome(true);
          }
        } catch (e) {
          if (response.data === null && response.errorCode === 409) {
            this.rdvExist = true;
            this._spinner = true;
            // this.messageError = 'rdvExist';
            this.messageError = response.message;
            this.errorModal.show();
          }
        }
      },
      (error) => {
        this._spinner = true;
        this.rdvExist = false;
        this.messageError = error.error.message;
        this.errorModal.show();
      //console.log('error sendCode => ', error);
      },
      () => {
        this._spinner = true;
      }
    );
  }

  nextVerification2() {
    if (this.prev_hand) {
      this.title = 't4';
      this.hidePrevious = false;
      this._verification = false;
      this.userInfo = true;
      this.contact.email = this.user_informations.email;
      this.incrementStep();
      this.incrementStep();
    } else {
      this.setTimer(5000);
      this.title = 't2';
      this.hidePrevious = false;
      this._verification = false;
      this.verification = true;
      this.prev_hand = false;
      this.showAlert = true;
      this.incrementStep();
    }
  }

  checkForm() {
    if (this.dossiers.length === 0) {
      return (this.applicant.firstName !== '' && this.applicant.lastName !== ''
        && this.applicant.gender !== '' && this.contact.mobile !== null
        && this.address.city !== '' && this.address.country !== ''
        && this.address.zipCode !== '' && this.address.street !== '' && this.birthInfo.city !== ''
        && this.birthInfo.country !== '' && this.birthInfo.day !== null && this.userSched.cidNumber !== '');
    } else {
      return (this.applicant.firstName !== '' && this.applicant.lastName !== '' && this.birthInfo.city !== ''
        && this.birthInfo.country !== '' && this.birthInfo.day !== null && this.userSched.cidNumber !== '' && this.applicant.gender !== '');
    }
  }

  nextUserInfos(value) {
    this.spinner = false;
    this.verificationInf = new Verification();
    this.subject = new Subject();
    this.verificationInf.type = this.linkType;
    this.verificationInf.token = this.user_informations.code;
    this.subject.contact = this.user_informations.email;
    this.subject.discriminator = this.user_informations.email;
    this.verificationInf.subject = this.subject;
    this.subscription = this.appointmentService.validateCode(this.verificationInf).subscribe(
      (resp) => {
      //console.log('response => ', resp);
        if (resp.data.valid) {
          this.incrementStep();
          this.title = 't4';
          this.hidePrevious = false;
          this.verification = false;
          this.userInfo = true;
          this.contact.email = this.user_informations.email;
        } else {
          this.respErrCode = true;
        }
      },
      (error) => {
      //console.log('An error occurred in nextConnexionParam() ! : ' + error);
      },
      () => {
        // console.log('Observable complete!');
        this.spinner = true;
      }
    );
  }

  nextCalIn(value: any) {
    if((value.telmobile == undefined || value.telmobile.number.match(this.selectedCountry.formats.phoneNumberFormat.regex)) && value.telmobile !== null){
      this.dates = [];
      this.dossiers.forEach(dossier => {
        this.dates.push(dossier.applicant.birthInfo.day);
      });
      if (this.cancel) {
        this.birthDate = this.birthInfo.day;
        this.contact.mobile = this.contact.mobile ? this.contact.mobile.e164Number : this.contact.mobile;
        this.contact.phone = this.contact.phone ? this.contact.phone.e164Number : this.contact.phone;
        this.applicant.contact = this.contact;
        this.applicant.address = this.address;
        this.applicant.birthInfo = this.birthInfo;
        if (this.dossiers.length === 0) {
          this.applicant.birthInfo.day = new DatePipe('en-US').transform(this.applicant.birthInfo.day, 'dd/MM/yyyy');
        }
        this.userSched.applicant = this.applicant;
        this.dossiers.push(this.userSched);
      }
      if (this.dossiers.length > 1) {
        for (let i = 0; i < this.dossiers.length; i++) {
          this.dossiers[i].applicant.birthInfo.day = new DatePipe('en-US').transform(this.dossiers[i].applicant.birthInfo.day, 'dd/MM/yyyy');
          this.dossiers[i].applicant.contact.mobile = this.dossiers[0].applicant.contact.mobile;
          this.dossiers[i].applicant.contact.phone = this.dossiers[0].applicant.contact.phone;
          this.dossiers[i].applicant.address.additional = this.dossiers[0].applicant.address.additional;
          this.dossiers[i].applicant.address.street = this.dossiers[0].applicant.address.street;
          this.dossiers[i].applicant.address.zipCode = this.dossiers[0].applicant.address.zipCode;
          this.dossiers[i].applicant.address.city = this.dossiers[0].applicant.address.city;
          this.dossiers[i].applicant.address.country = this.dossiers[0].applicant.address.country;
        }
      }
      if (this.dossiers.length === 1 && !this.cancel) {
        this.dossiers[0].applicant.birthInfo.day = new DatePipe('en-US').transform(this.dossiers[0].applicant.birthInfo.day, 'dd/MM/yyyy');
      }
      this.userInfos.dossiers = this.dossiers;
      this.incrementStep();
      this.title = 'calendar';
      this.hidePrevious = false;
      this.isCalendar = true;
      this.userInfo = false;
    }
  }

  nextRecap() {
    this.hidePrevious = false;
    this.recapitulatif = true;
    this.isCalendar = false;
    this.title = 't7';
    this.incrementStep();
  }

  previousCalendar() {
    this.recapitulatif = false;
    this.isCalendar = true;
    this.title = 'calendar';
    this.decrementStep();
  }

  previousUserInf() {
  //console.log(this.dates);
  //console.log(this.dossiers);
    for (let i = 0; i < this.dossiers.length; i++) {
      for (let j = 0; j < this.dates.length; j++) {
        if (i === j) {
          this.dossiers[i].applicant.birthInfo.day = this.dates[j];
        }
      }
    }
    if (this.cancel) {
      this.removeBen(this.dossiers.length - 1);
    }
    this.applicant.birthInfo.day = this.birthDate;
    this.title = 't4';
    this.isCalendar = false;
    this.userInfo = true;
    this.decrementStep();
  }

  _previousVerification() {
    this.decrementStep();
    this._verification = true;
    this.verification = false;
    this.title = 't1';
    this.hidePrevious = true;
    this.myRecaptcha = true;
    this.respErrCode = false;
  }

  previousVerification() {
    if (this.prev_hand) {
      this.decrementStep();
      this.decrementStep();
      this.title = 't1';
      this.hidePrevious = true;
      this._verification = true;
      this.userInfo = false;
    } else {
      this.decrementStep();
      this.verification = true;
      this.title = 't2';
      this.userInfo = false;
    }
  }

  incrementStep() {
    if (this.value !== this.max) {
      this.value = this.value + 1;
      this.dynamic = this.value;
      this.type = '';
      this.i++;
    }
  }

  decrementStep() {
    if (this.value > 1) {
      this.value = this.value - 1;
      this.dynamic = this.value;
      this.type = '';
      this.i--;
    }
  }

  addBenif() {
    if (!this.cancel) {
      this.cancel = true;
      this.initOnj();
    } else {
      this.contact.mobile = this.contact.mobile ? this.contact.mobile.e164Number : this.contact.mobile;
      this.contact.phone = this.contact.phone ? this.contact.phone.e164Number : this.contact.phone;
      this.applicant.contact = this.contact;
      this.applicant.address = this.address;
      this.applicant.birthInfo = this.birthInfo;
      this.userSched.applicant = this.applicant;
      this.dossiers.push(this.userSched);
      this.initOnj();
    }
  }

  initOnj() {
    this.address = new Address();
    this.birthInfo = new BirthInfo();
    this.applicant = new Applicant();
    this.contact = new Contact();
    this.contact.email = this.user_informations.email;
    this.userSched = new UserSched();
  }

  removeBen(index: any) {
    this.dossiers.splice(index, 1);
  }

  cancelCurrentBen() {
    this.cancel = false;
  }

  calendarEvent(event) {
    this.userInfos.event = event.idEvent;
    this.dateEvent = event.dateEvent;
    this.hourStart = event.hourStart;
    this.hourEnd = event.hourEnd;
    this.userInfos.agency = this.agency;
  //console.log(this.userInfos);
    this.nextRecap();
  }

  navigateToHome(isMessageSend) {
    this.router.navigate(['home'], {
      queryParams: {
        isMessageSend: isMessageSend
      }
    });
  }

  handleExpire() {
    this.myRecaptcha = true;
    this.btn_send = false;
  }

  handleLoad() {
    // this.myRecaptcha = false;
  }

  handleSuccess($event: string) {
    this.ekaidiRecaptcha = $event;
    this.myRecaptcha = false;
    this.btn_send = false;
    if (this.user_informations.email === '') {
      this.email_req = true;
    }
  }

  handleReset() {
    this.myRecaptcha = false;
  }

  scrollToTopOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {
    this.scrollToTopOnInit();
  }

  onchangeAmb(value: any) {
    this.address.country = '';
    this.rescountry = this.test;
    this.agency = this.test;
    this.confirmModal.show();
    this.setCountry(this.test);
  }

  setCountry(agencyRef) {
    this._country = [];
    this.agencies.forEach(agency => {
      if (agency.reference === agencyRef) {
        this._country = this._country.concat(this.resCountries.filter(country =>
          country.ambaRattachement && agency.code === country.ambaRattachement));
      }
    });
    this._country.forEach(country =>
      this.onlyCountries.push(Object.values(this.CountryISO).find(val => val === country.code.toLowerCase())));
  //console.log("_country" , this._country);
  }

  getZipCodePattern() {
    if (this._country.length !== 0 && this.address.country) {
      const country = this._country.filter(pays => pays.code === this.address.country)[0];
      return country.formats?.zipCodeFormat?.regex;
    }
    return '';
  }

  getZipCodePlaceholder() {
    if (this._country.length !== 0 && this.address.country) {
      const country = this._country.filter(pays => pays.code === this.address.country)[0];
      return country.formats?.zipCodeFormat?.placeholder;
    }
    return "{{ 'cod_postal' | translate}}";
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}

