export class BirthInfo {
  constructor(
    public city?: string,
    public country?: string,
    public day?: any,
  ) {
    this.city = '';
    this.country = '';
    this.day = null;
  }
}
