export class Contact {
  constructor(
    public email?: string,
    public phone?: any,
    public mobile?: any
  ) {
    this.email = '';
    this.phone = null;
    this.mobile = null;
  }
}
