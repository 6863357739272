import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { siteKey } from '../../../app-config/app.recap';
import {LangService} from "../../../shared/services/lang.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('accExist') accExist;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  langue: string = '';
  succReg  = this.langService.succSub;
  col_show_1: Boolean = false;
  col_show_2: Boolean = false;
  col_show_3: Boolean = false;
  show: Boolean = false;
  ekaidiRecaptcha: any = null;
  siteKey: any = siteKey;

  credentials = {
    username: '',
    password: ''
  };

  myRecaptcha: Boolean = false;

  constructor(private router: Router,
              private langService: LangService,
              private modalService: BsModalService) {
  }

  ngOnInit() {
    if (this.langService.selectedLang) {
      this.langue = 'fr';
      this.handleReset();
    } else {
      this.langue = 'en';
      this.handleReset();
    }
  }

  ngAfterViewInit(): void {
    if (this.succReg) {
      this.openModalAccExist(this.accExist);
    }
    this.scrollToTop();
  }

  scrollToTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  openModalAccExist(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onRedirectRegister() {
    this.router.navigateByUrl('/register');
  }

  onRedirectHome() {
    this.router.navigateByUrl('/home');
  }

  onLogin(value: any) {
    // console.log('okk !');
  }

  handleExpire() {
    this.myRecaptcha = false;
  }

  handleLoad() {
  }

  handleSuccess($event: string) {
    this.ekaidiRecaptcha = $event;
    this.myRecaptcha = true;
  }

  handleReset() {
  }

}
