<html class="back_col">
<header>
  <nav class="navbar navbar-expand-lg navbar-light Header">
    <h5 class="my-0 resp-mar font-weight-normal">
      <i class="fa fa-long-arrow-left cursor-point"
         data-toggle="tooltip" title="Acceuil"
         (click)="onRedirectHome()"></i></h5>
    <div>
      <img class="ekaidi_img" src="assets/images/ekaidi_max.png">
    </div>
    <button class="navbar-toggler" type="button" (click)="show=!show">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse text-center" [ngClass]="{'show' : show}">
      <ul class="nav navbar-nav ml-auto">
        <div class="nav-link my-2 my-md-0 mr-md-3">
          <button class="btn btn-primary mb-1 respon" (click)="onRedirectlogin()">
            <i class="fa fa-lock mr-2" aria-hidden="true"></i>
            <span class="button-font" translate>haveacc</span>
          </button>
        </div>
      </ul>
    </div>
  </nav>
</header>
<body class="marg container back_col">
<p *ngIf="!recap">
  <span class="font-col" (click)="onRedirectHome()" translate>bred_appointment</span>
  <span class="_font-col text-muted" translate>bred</span>
</p>
<div>
  <div class="row justify-content-center">
    <div [ngClass]="recap ? col_12 : col_7" class="order-xl-1 mb-2 mb-xl-0">
      <p *ngIf="recap">
        <span class="font-col" (click)="onRedirectHome()" translate>bred_appointment</span>
        <span class="_font-col text-muted" translate>bred</span>
      </p>
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>create_acc</h5>
        </div>
        <div class="mb-2">
          <progressbar [max]="max" [value]="dynamic" [type]="type">
            <span class="text-nowrap">{{dynamic}} / {{max}}</span>
          </progressbar>
        </div>
        <div *ngIf="showAlert" class="m-2 alert alert-info alert-dismissible fade show text-center" role="alert">
          <span class="text-muted jsut_text" translate>send_code</span>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body mt-3 mb-2 mr-4 ml-4">
          <h3 class="text-center text-muted mb-4">{{ title | translate }}</h3>
          <!--Vérification 1/1-->
          <div *ngIf="_verification">
            <form #form="ngForm" (ngSubmit)="form.valid" novalidate>
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="_email" translate>
                  email_add
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="email"
                         name="email"
                         id="_email"
                         [(ngModel)]="user_informations.email"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (email.touched || form.submitted) && email.errors?.required && email.invalid || email.errors?.pattern || email_req }"
                         #email="ngModel"
                         placeholder="{{ 'email_add' | translate }}"
                         required
                         pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                         (input)="email_req=false">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(email.touched || form.submitted) && email.errors?.required || email_req" translate>
                                email_req
            </span>
                <span class="text-danger font-col"
                      *ngIf="email.errors &&(email.touched || email.dirty)">
                  <p [hidden]="!email.errors?.pattern" translate>
                          email_inv
                   </p>
            </span>
              </div>
              <hr class="mt-4">
              <div class="row mt-4 justify-content-center">
                <div>
                  <ngx-recaptcha2 #captchaElem
                                  [(ngModel)]="ekaidiRecaptcha"
                                  name="ekaidiRecaptcha"
                                  (reset)="handleReset()"
                                  (expire)="handleExpire()"
                                  [siteKey]="siteKey"
                                  (load)="handleLoad()"
                                  (success)="handleSuccess($event)"
                                  [hl]="langue">
                  </ngx-recaptcha2>
                </div>
              </div>
              <hr class="mt-4">
              <div class="row mt-4">
                <div class="col-12">
                  <button type="button"
                          [disabled]="!myRecaptcha || email.invalid"
                          class="btn btn-block"
                          [ngClass]="{ 'btn-secondary' : (!myRecaptcha || email.invalid), 'btn-primary' : (myRecaptcha || !email.invalid)}"
                          (click)="onReceiveCode(email.value)">
                    <span [hidden]="spinner" class="spinner-border spinner-border-sm mr-2" role="status"
                          aria-hidden="true" translate></span>
                    <span [hidden]="btn_send" translate>code_rec</span>
                    <span [hidden]="!btn_send" translate>send</span>
                    <i [hidden]="!btn_send" class="fa fa-check ml-3" aria-hidden="true"></i>
                    <i [hidden]="btn_send" class="fa fa-paper-plane ml-3" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        hidden
                        class="btn pull-right"
                        [ngClass]="{ 'btn-secondary' : nextStep, 'btn-primary' : !nextStep}"
                        [disabled]="nextStep">
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--Vérification 2/2-->
          <div *ngIf="verification">
            <form #form="ngForm" (ngSubmit)="form.valid && nextConnexionParam(form.value)" novalidate>
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="email" translate>
                  email_add
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="email"
                         name="email"
                         id="email"
                         [(ngModel)]="user_informations.email"
                         class="form-control"
                         #email="ngModel"
                         disabled>
                </div>
              </div>

              <div class="form-group  mt-4">
                <label class="font-col-label  form-control-label text-muted" for="code" translate>
                  code_verif
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-open-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="text"
                         name="code"
                         id="code"
                         [(ngModel)]="user_informations.code"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (code.touched || form.submitted) && code.errors?.required || respErrCode }"
                         #code="ngModel"
                         placeholder="{{ 'code_verif' | translate }}"
                         required
                         (input)="respErrCode=false;">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(code.touched || form.submitted) && code.errors?.required" translate>
                                err_code_req<br>
                </span>
                <span class="text-danger font-col" *ngIf="respErrCode" translate>
                                valid_code
                </span>

              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="_previousVerification()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="nextStep && !myRecaptcha">
                  <span [hidden]="spinner" class="spinner-border spinner-border-sm mr-2" role="status"
                        aria-hidden="true" translate></span>
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--CONNEXION PARAM-->

          <div *ngIf="connexion_param">
            <form #formConnexion="ngForm" [appMatchPassword]="['newPassword', 'confNewPassword']"
                  (ngSubmit)="formConnexion.valid && nextGeneralInfo(formConnexion.value)" novalidate>
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="username" translate>
                  idt
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="text"
                         name="username"
                         id="username"
                         [(ngModel)]="user_informations.username"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (name.touched || formConnexion.submitted) && name.errors?.required || name.errors?.pattern || respErrCode}"
                         #name="ngModel"
                         placeholder="{{ 'idt' | translate }}"
                         required
                         pattern="[A-Za-z0-9]+"
                         (input)="onVerifyUsername()"
                         (blur)="onVerifyUsername()">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(name.touched || formConnexion.submitted) && name.errors?.required" translate>
                                idt_req
                </span>
                <span class="text-danger font-col"
                      *ngIf="name.errors &&(name.touched || name.dirty)">
                           <p [hidden]="!name.errors?.pattern" translate>
                                idt_alpha
                           </p>
                 </span>
                <span class="text-danger font-col" *ngIf="respErrCode" translate>
                                valid_user
                </span>
              </div>
              <div class="form-group  mt-4">
                <label class="font-col-label  form-control-label text-muted" for="newPassword" translate>
                  password
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="password"
                         maxlength="20"
                         name="newPassword"
                         id="newPassword"
                         [(ngModel)]="user_informations.newPassword"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (newPassword.touched || formConnexion.submitted) && newPassword.errors?.required }"
                         #newPassword="ngModel"
                         placeholder="{{ 'password' | translate }}"
                         appPasswordPattern
                         required>
                </div>
                <span class="text-danger font-col"
                      *ngIf="(newPassword.touched || formConnexion.submitted) && newPassword.errors?.required"
                      translate>
                                err_password
                  </span>
                <span class="text-danger font-col" *ngIf="newPassword.touched && newPassword.errors?.invalidPassword"
                      translate>
                           err_password_
                </span>
              </div>
              <div class="form-group  mt-4">
                <label class="font-col-label  form-control-label text-muted" for="email" translate>
                  pwd_conf
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="password"
                         maxlength="20"
                         name="confNewPassword"
                         id="confNewPassword"
                         [(ngModel)]="user_informations.confNewPassword"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (confNewPassword.touched || formConnexion.submitted) && confNewPassword.errors?.required }"
                         #confNewPassword="ngModel"
                         required
                         placeholder="{{ 'pwd_conf' | translate }}">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(confNewPassword.touched || formConnexion.submitted) && confNewPassword.errors?.required"
                      translate>
                               conf_req
                            </span>
                <span class="text-danger font-col"
                      *ngIf="confNewPassword.touched && confNewPassword.errors?.passwordMismatch" translate>
                               pwd_corr
              </span>
              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="previousVerification()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="nextStep && !myRecaptcha || respErrCode || user_informations.username === ''">
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--GENERAL INFO-->

          <div *ngIf="general_infos">
            <form #formGenInfo="ngForm" (ngSubmit)="formGenInfo.valid && nextContactAdress(formGenInfo.value)"
                  novalidate>
              <div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="prenom" translate>
                        prenom
                        <span class="text-danger font-col">
                           *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="prenom"
                               id="prenom"
                               [(ngModel)]="user_informations.prenom"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (prenom.touched || formGenInfo.submitted) && prenom.errors?.required }"
                               #prenom="ngModel"
                               required
                               placeholder="{{ 'prenom' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(prenom.touched || formGenInfo.submitted) && prenom.errors?.required" translate>
                               pren_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="nom" translate>
                        nom_nec
                        <span class="text-danger font-col">
                    *
                  </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="nom"
                               id="nom"
                               [(ngModel)]="user_informations.nom"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (nom.touched || formGenInfo.submitted) && nom.errors?.required }"
                               #nom="ngModel"
                               required
                               placeholder="{{ 'nom_nec' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(nom.touched || formGenInfo.submitted) && nom.errors?.required" translate>
                               nom_req
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="situation" translate>
                        sit_mat
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="situation"
                                id="situation"
                                [(ngModel)]="user_informations.situation"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (situation.touched || formGenInfo.submitted) && situation.errors?.required }"
                                #situation="ngModel"
                                required
                                (input)="onchange($event.target.value)">
                          <option value="SINGLE" translate>cel</option>
                          <option value="MARRIED" translate>mar</option>
                          <option value="WIDOWER" translate>veu</option>
                          <option value="CONCUBINAGE" translate>conc</option>
                          <option value="DIVORCED" translate>div</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(situation.touched || formGenInfo.submitted) && situation.errors?.required"
                            translate>
                              sit_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="usageNom" translate>
                        nom_mar
                        <span class="text-danger font-col">
                          *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="usageNom"
                               id="usageNom"
                               [(ngModel)]="user_informations.usageNom"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (usageNom.touched || formGenInfo.submitted) && usageNom.errors?.required }"
                               #usageNom="ngModel"
                               placeholder="{{ 'nom_mar' | translate }}"
                               [disabled]="situation.value !='MARRIED' || genre.value !='F'">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(usageNom.touched || formGenInfo.submitted) && usageNom.errors?.required" translate>
                               nom_mar_req
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="dateNaissance" translate>
                        naiss
                        <span class="text-danger font-col">
                         *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="Date"
                               name="dateNaissance"
                               id="dateNaissance"
                               [(ngModel)]="user_informations.dateNaissance"
                               class="form-control"
                               #dateNaissance="ngModel"
                               required
                               placeholder="{{ 'naiss' | translate }}"
                               [ngClass]="{ 'is-invalid': (dateNaissance.touched && dateNaissance.errors?.required)
                                                  || (dateNaissance.value != '' && dateNaissance.value > latest_date)}">
                        <span class="text-danger font-col"
                              *ngIf="dateNaissance.touched && dateNaissance.errors?.required" translate>
                                naiss_req
                       </span>
                        <span class="text-danger font-col"
                              *ngIf="(dateNaissance.value != '' && dateNaissance.value > latest_date)" translate>
                                            naiss_inf<br>
                       </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="genre" translate>
                        gen
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="genre"
                                id="genre"
                                [(ngModel)]="user_informations.genre"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (genre.touched || formGenInfo.submitted) && genre.errors?.required }"
                                #genre="ngModel"
                                required
                                (input)="onchange($event.target.value)">
                          <option value="M" translate>men</option>
                          <option value="F" translate>wom</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(genre.touched || formGenInfo.submitted) && genre.errors?.required" translate>
                               gen_req
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="pays" translate>
                        pay_naiss
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="pays"
                                id="pays"
                                [(ngModel)]="user_informations.pays"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (pays.touched || formGenInfo.submitted) && pays.errors?.required }"
                                #pays="ngModel"
                                required
                                (change)="onCountChange($event.target.value)">
                          <option *ngFor="let country of countries">
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(pays.touched || formGenInfo.submitted) && pays.errors?.required" translate>
                               pay_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="city_state_cont">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="prefReg" translate>
                        pref
                      </label>
                      <span class="text-danger font-col">
                            *
                      </span>
                      <div class="input-group">
                        <select class="form-control rect"
                                type="text"
                                name="prefReg"
                                id="prefReg"
                                [(ngModel)]="user_informations.prefReg"
                                [ngClass]="{ 'is-invalid': (prefReg.touched || formGenInfo.submitted) && prefReg.errors?.required}"
                                #prefReg="ngModel"
                                required
                                (change)="stateChange($event.target.value)">
                          <option></option>
                          <option *ngFor="let state of _unique_state">
                            {{ state }}
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(prefReg.touched || formGenInfo.submitted) && prefReg.errors?.required"
                            translate>
                               pref_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="!city_state_cont">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="prefReg_" translate>
                        pref
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="prefReg_"
                               id="prefReg_"
                               [(ngModel)]="user_informations.prefReg"
                               class="form-control"
                               placeholder="{{ 'pref' | translate }}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6" *ngIf="city_state_cont">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="commune_" translate>
                        com_naiss
                        <span class="text-danger font-col">
                            *
                        </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="commune_"
                                id="commune_"
                                [(ngModel)]="user_informations.commune"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (commune.touched || formGenInfo.submitted) && commune.errors?.required }"
                                #commune="ngModel"
                                required>
                          <option></option>
                          <option *ngFor="let city of _city">
                            {{ city }}
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(commune.touched || formGenInfo.submitted) && commune.errors?.required" translate>
                              com_naiss_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="!city_state_cont">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="commune" translate>
                        com_naiss
                        <span class="text-danger font-col">
                            *
                        </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="commune"
                               id="commune"
                               [(ngModel)]="user_informations.commune"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (commune.touched || formGenInfo.submitted) && commune.errors?.required }"
                               placeholder="{{ 'com_naiss' | translate }}"
                               #commune="ngModel"
                               required>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(commune.touched || formGenInfo.submitted) && commune.errors?.required" translate>
                              com_naiss_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="profession" translate>
                        prof
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="profession"
                                id="profession"
                                [(ngModel)]="user_informations.profession"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (profession.touched || formGenInfo.submitted) && profession.errors?.required }"
                                #profession="ngModel"
                                required>
                          <option value="FARMER" translate>prof_1</option>
                          <option value="HEAD_OF_COMPANY" translate>prof_2</option>

                          <option value="MERCHANTS" translate>prof_3
                          </option>
                          <option value="FREELANCE" translate>prof_4</option>

                          <option value="SENIOR_PUBLIC_SECTOR" translate>prof_5
                          </option>
                          <option value="HIGHS_SKILLED_EXECUTIVE" translate>prof_6
                          </option>
                          <option value="PROFESSOR_SCIENTIST" translate>prof_7
                          </option>
                          <option value="INFORMATION_ENTERTAINMENT_ARTIST" translate>prof_8

                          </option>
                          <option value="ENGINEER" translate>prof_9
                          </option>
                          <option value="PROFESSOR_TEACHER" translate>prof_10
                          </option>
                          <option value="CLERGY_RELIGIOUS" translate>prof_11
                          </option>
                          <option value="TECHNICAL_OFFICER" translate>prof_12</option>

                          <option value="FOREMAN" translate>prof_13
                          </option>
                          <option value="POLICE_MILITARY" translate>prof_14
                          </option>
                          <option value="CORPORATE_EMPLOYEE" translate>prof_15
                          </option>
                          <option value="HEALTH_SOCIAL_ASSIST" translate>prof_16
                          </option>
                          <option value="DOMESTIC_HOUSEHOLD_SERVICES" translate>prof_17
                          </option>
                          <option value="SKILLED_WORKER" translate>prof_18</option>

                          <option value="UNSKILLED_WORKER" translate>prof_19
                          </option>
                          <option value="DRIVER" translate>prof_20</option>

                          <option value="RETIRED" translate>prof_21</option>

                          <option value="UNEMPLOYED" translate>prof_22</option>

                          <option value="PUPILS_STUDENTS" translate>prof_23</option>

                          <option value="ANOTHER" translate>prof_24</option>

                          <option value="HOUSE_WIFE_HUSBAND" translate>prof_25
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(profession.touched || formGenInfo.submitted) && profession.errors?.required"
                            translate>
                               prof_req
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="previousConnParam()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="nextStep && !myRecaptcha">
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--ADRESS CONTACT-->

          <div *ngIf="contact_adress">
            <form #formGenInfo="ngForm" (ngSubmit)="formGenInfo.valid && nextJustificatifs(formGenInfo.value)"
                  novalidate>
              <div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="rue" translate>
                        num_rue
                        <span class="text-danger font-col">
                           *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="rue"
                               id="rue"
                               [(ngModel)]="user_informations.rue"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (rue.touched || formGenInfo.submitted) && rue.errors?.required }"
                               #rue="ngModel"
                               required
                               placeholder="{{ 'num_rue' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(rue.touched || formGenInfo.submitted) && rue.errors?.required" translate>
                               rue_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="complementAdd" translate>
                        comp_add
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="complementAdd"
                               id="complementAdd"
                               [(ngModel)]="user_informations.complementAdd"
                               class="form-control"
                               #complementAdd="ngModel"
                               placeholder="{{ 'comp_add' | translate  }}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="paysres" translate>
                        resCountry
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="paysres"
                                id="paysres"
                                [(ngModel)]="user_informations.rePays"
                                (ngModelChange)="handleResidenceChange($event)"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (paysresi.touched || formGenInfo.submitted) && paysresi.errors?.required }"
                                #paysresi="ngModel"
                                required>
                          <option [ngValue]="country.code" *ngFor="let country of resCountries">
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(paysresi.touched || formGenInfo.submitted) && paysresi.errors?.required" translate>
                               resCountry_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="selectedAddress">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="departementR" translate>
                        dep_reg
                        <span class="text-danger font-col">
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="departementR"
                               id="departementR"
                               [(ngModel)]="user_informations.departementR"
                               class="form-control"
                               #departementR="ngModel"
                               placeholder="{{ 'dep_reg' | translate }}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="selectedAddress">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="codePostal" translate>
                        cod_postal
                        <span class="text-danger font-col" *ngIf="selectedCountry.formats?.zipCodeFormat?.require">
                            *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="codePostal"
                               id="codePostal"
                               [(ngModel)]="user_informations.codePostal"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (codePostal.touched || formGenInfo.submitted) && codePostal.errors?.required }"
                               #codePostal="ngModel"
                               [required]="selectedCountry.formats?.zipCodeFormat?.require"
                               (ngModelChange)="user_informations.codePostal = $event.toUpperCase()"
                               placeholder="{{selectedCountry.formats?.zipCodeFormat?.placeholder}}"
                               [pattern]="selectedCountry.formats?.zipCodeFormat?.regex">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(codePostal.touched || formGenInfo.submitted) && codePostal.errors?.required"
                            translate>
                               code_req
                       </span>
                      <span class="text-danger font-col"
                            *ngIf="codePostal.errors && codePostal.touched">
                           <p [hidden]="!codePostal.invalid" translate>
                                cod_postal_inv
                           </p>
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="selectedAddress">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="communeResidance" translate>
                        com_res
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <input class="form-control"
                               name="communeResidance"
                               id="communeResidance"
                               [(ngModel)]="user_informations.communeResidance"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (communeResidance.touched || formGenInfo.submitted) && communeResidance.errors?.required }"
                               #communeResidance="ngModel"
                               required
                               placeholder="{{ 'com_res' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(communeResidance.touched || formGenInfo.submitted) && communeResidance.errors?.required"
                            translate>
                               comu_req
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="reEmail">
                        Email
                        <span class="text-danger font-col">
                            *
                          </span>
                      </label>
                      <div class="input-group">
                        <input type="email"
                               class="form-control"
                               name="email"
                               id="reEmail"
                               [(ngModel)]="user_informations.email"
                               disabled
                               class="form-control"
                               #email="ngModel">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="selectedAddress">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="telephone" translate>
                        mobile
                        <span class="text-danger font-col" *ngIf="selectedCountry.formats?.phoneNumberFormat?.require">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <ngx-intl-tel-input
                          #telephone="ngModel"
                          [cssClass]="'form-control'"
                          [enableAutoCountrySelect]="false"
                          [onlyCountries]="onlyCountries"
                          [enablePlaceholder]="false"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="selectedCountry.code"
                          [maxLength]="15"
                          [phoneValidation]="phoneValidation"
                          [numberFormat]="PhoneNumberFormat.International"
                          [separateDialCode]="true"
                          name="telephone"
                          [inputId]="telephone"
                          [(ngModel)]="user_informations.telephone"
                          (ngModelChange)="checkPhoneNumber($event)"
                          [ngClass]="{ 'is-invalid': (telephone.touched || formGenInfo.submitted) && telephone.errors?.required }"
                          (countryChange)="checkPhoneNumber($event)"
                        ></ngx-intl-tel-input>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(telephone.touched || formGenInfo.submitted) && hasRequiredErrors()"
                            translate>
                               mob_req
                       </span>
                      <span class="text-danger font-col"
                            *ngIf="(telephone.errors || hasErrors) && telephone.touched">
                           <p [hidden]="!hasErrors" translate>
                                num_inv
                           </p>
                       </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="previousGenInfo()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="(nextStep && !myRecaptcha) || ((!user_informations.codePostal && selectedCountry?.formats?.zipCodeFormat?.require) || !user_informations.communeResidance
                        || (!user_informations.telephone && selectedCountry?.formats?.phoneNumberFormat?.require) || !user_informations.rePays || !user_informations.rue) || ((telephone && telephone.errors) || hasErrors)
                        || (codePostal && codePostal.errors)">
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--JUSTIFICATIF-->

          <div *ngIf="justificatifs">
            <div class="text-center pt-4">
              <p class="jsut_text">
                <span translate>span1</span>
                <span style="color: #c90000" translate>span2</span>
                <span translate>span3</span>
              </p>
            </div>
            <div>
              <table class="table table-bordered mt-5">
                <tbody>
                <tr>
                  <td colspan="4">
                    <strong translate>doc</strong><span translate>doc_acc</span><br>
                    <strong translate>mineur</strong>
                    <span translate>juj</span>
                  </td>
                  <td>
                    <button class="btn btn-dark btn-sm"
                            (click)="chooseFile.show()"
                            aria-label="Upload">
                      <i class="fa fa-upload" aria-hidden="true">
                        <span class="ml-2" translate>up</span>
                      </i>
                    </button>
                  </td>
                  <td>
                    <i style="color: green" [hidden]="justResultSucc" class="fa fa-check-square-o fa-2x"
                       aria-hidden="true"></i>
                    <i style="color: red" [hidden]="justErr" class="fa fa-window-close fa-2x" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" translate>
                    photo
                  </td>
                  <td>

                    <label class="btn btn-dark btn-sm border_cust">
                      <i class="fa fa-upload" aria-hidden="true">
                        <span class="ml-2" translate>up</span>
                      </i>
                      <input type="file" accept="image/*" id="photo-input" style="display: none !important;"
                             (change)="showContentPopup=true;fileChangeEvent($event);chooseImage.show()">
                    </label>
                  </td>
                  <td>
                    <i style="color: green" [hidden]="justPhotoSucc" class="fa fa-check-square-o fa-2x"
                       aria-hidden="true"></i>
                    <i style="color: red" [hidden]="justPhotoErr" class="fa fa-window-close fa-2x"
                       aria-hidden="true"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-check mt-5">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="checkCheckbox($event)">
              <label class="form-check-label" for="exampleCheck1">
                <p class="font-card-body" translate>cond_gener
                  <a class="condition_redirect" (click)="openNewTabPdf()" translate>cg</a>
                </p>
                <span class="text-danger font-col"
                      *ngIf="justifAccept && !justResultSucc && !justPhotoSucc" translate>
                              just_acg
                </span>
              </label>
            </div>
            <div>
              <hr class="mt-4">
              <button type="button"
                      class="btn btn-light"
                      [hidden]="hidePrevious"
                      (click)="previousContactAdress()">
                <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                <span translate>prev</span>
              </button>
              <button type="submit"
                      class="btn btn-primary pull-right"
                      [disabled]="justResultSucc || justPhotoSucc || justifAccept"
                      (click)="nextRecap()">
                <span translate>next</span>
                <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <!--RECAP-->

          <div *ngIf="recap">
            <div class="mt-5">
              <!--CONNEXION PARAM-->
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>param</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="font-col-label  form-control-label text-muted" for="_username" translate>
                      idt
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="rect input-group-text">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                      </div>
                      <input type="text"
                             name="username"
                             id="_username"
                             [(ngModel)]="user_informations.username"
                             class="form-control"
                             disabled>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="font-col-label  form-control-label text-muted" for="_newPassword" translate>
                      password
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="rect input-group-text">
                          <i class="fa fa-lock" aria-hidden="true"></i>
                        </div>
                      </div>
                      <input type="password"
                             name="newPassword"
                             id="_newPassword"
                             [(ngModel)]="user_informations.newPassword"
                             class="form-control"
                             disabled>
                    </div>
                  </div>
                </div>
              </div>

              <!--GENERAL INFO-->
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>inf_gen</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>
              <div>
                <div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_prenom" translate>
                          prenom
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="prenom"
                                 id="_prenom"
                                 [(ngModel)]="user_informations.prenom"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_nom" translate>
                          nom_nec
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="nom"
                                 id="_nom"
                                 [(ngModel)]="user_informations.nom"
                                 class="form-control"
                                 disabled>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_situation" translate>
                          sit_mat
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input class="form-control rect"
                                 name="situation"
                                 id="_situation"
                                 value="{{ selectedOption.situation | translate}}"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_usageNom" translate>
                          nom_mar
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="usageNom"
                                 id="_usageNom"
                                 [(ngModel)]="user_informations.usageNom"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_dateNaissance" translate>
                          naiss
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="Date"
                                 name="dateNaissance"
                                 id="_dateNaissance"
                                 [(ngModel)]="user_informations.dateNaissance"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_genre" translate>
                          gen
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input class="form-control rect"
                                 name="genre"
                                 id="_genre"
                                 value="{{ selectedOption.genre | translate}}"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_prefReg" translate>
                          pref
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="prefReg"
                                 id="_prefReg"
                                 [(ngModel)]="user_informations.prefReg"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_pays" translate>
                          pay_naiss
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input class="form-control rect"
                                 name="pays"
                                 id="_pays"
                                 [(ngModel)]="user_informations.pays"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_commune" translate>
                          com_naiss
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="commune"
                                 id="_commune"
                                 [(ngModel)]="user_informations.commune"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_profession" translate>
                          prof
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input class="form-control rect"
                                 name="profession"
                                 id="_profession"
                                 value="{{ selectedOption.profession | translate}}"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ADRESS CONTACT-->
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>add_cont</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>
              <div>
                <div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rue" translate>
                          num_rue
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="rue"
                                 id="_rue"
                                 [(ngModel)]="user_informations.rue"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_complementAdd" translate>
                          comp_add
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="complementAdd"
                                 id="_complementAdd"
                                 class="form-control"
                                 [(ngModel)]="user_informations.complementAdd"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_codePostal" translate>
                          cod_postal
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="codePostal"
                                 id="_codePostal"
                                 [(ngModel)]="user_informations.codePostal"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_communeResidance" translate>
                          com_res
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input class="form-control"
                                 name="communeResidance"
                                 id="_communeResidance"
                                 [(ngModel)]="user_informations.communeResidance"
                                 class="form-control"
                                 disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_departementR" translate>
                          dep_reg
                          <span class="text-danger font-col">
                       </span>
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 name="departementR"
                                 id="_departementR"
                                 class="form-control"
                                 [(ngModel)]="user_informations.departementR"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rePays" translate>
                          pays
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 class="form-control"
                                 name="_rePays"
                                 id="_rePays"
                                 [(ngModel)]="user_informations.rePaysName"
                                 disabled>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_reEmail">
                          Email
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="email"
                                 class="form-control"
                                 name="email"
                                 id="_reEmail"
                                 [(ngModel)]="user_informations.email"
                                 disabled>

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_telephone" translate>
                          mobile
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="rect input-group-text">
                              <i class="fa fa-address-book-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="text"
                                 class="form-control"
                                 name="telephone"
                                 id="_telephone"
                                 [(ngModel)]="user_informations.telephone.number"
                                 class="form-control" disabled>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- liste des justificatiofs -->
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>just_list</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <table class="table table-bordered text-center">
                <thead>
                <tr>
                  <th scope="col" class="text-info" translate>name</th>
                  <th scope="col" class="text-info" translate>nbr</th>
                  <th scope="col" class="text-info" translate>date_del</th>
                  <th scope="col" class="text-info" translate>date_exp</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" translate>{{ user_informations.docName }}</th>
                  <td>{{ user_informations.docNum }}</td>
                  <td>{{ user_informations.dateDeliv | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ user_informations.dateExp | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                  <th scope="row" translate>id_pho</th>
                  <th></th>
                  <td>{{ latest_date | date: 'dd/MM/yyyy' }}</td>
                  <th></th>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <hr class="mt-4">
              <div *ngIf="profileCreated" class="m-4 alert alert-info text-center" role="alert">
                <span class="text-muted jsut_text" translate>profile_cre <span class="text-danger"> {{ count }} sec</span></span>
              </div>
              <button type="button"
                      class="btn btn-light"
                      [hidden]="hidePrevious"
                      (click)="previousJustificatif()">
                <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                <span translate>edit</span>
              </button>
              <button type="button"
                      (click)="onSendData()"
                      class="btn btn-primary pull-right"
                      [disabled]="nextStep && !myRecaptcha">
                <span [hidden]="spinner" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                      translate></span>
                <span translate>val</span>
                <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <!--########-->
        </div>
      </div>
    </div>
    <div *ngIf="!recap" class="col-xl-5 order-xl-2 mb-2 mb-xl-0">
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>help</h5>
        </div>
        <div *ngIf="_verification" class="card-body mt-3 mb-2">
          <div id="_verification-id">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  <span translate>car_email</span>
                </a>
              </div>
              <div id="_collapseTwo-ver" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body" translate>
                  email_ex
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="_collapseOne-ver"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>step_a</span><br>
                  <span translate>step_b</span><br>
                  <span translate>step_c</span><br>
                  <span translate>step_d</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cooki</span>
                </a>
              </div>
              <div id="_collapseThree-ver" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cooki_desc</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="verification" class="card-body mt-3 mb-2">
          <div id="verification-id">
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="collapseOne-ver"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>ver_desc</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cooki</span>
                </a>
              </div>
              <div id="collapseThree-ver" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cooki_desc</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="connexion_param" class="card-body mt-3 mb-2">
          <div id="connexion_param-id">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  <span translate>pwd_car</span>
                </a>
              </div>
              <div id="collapseTwo" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body" translate>
                  err_password_
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="collapseOne"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>step_pwd</span><br>
                  <span translate>step_pwd_a</span><br>
                  <span translate>step_pwd_b</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cookies</span>
                </a>
              </div>
              <div id="collapseThree" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cook_det</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="general_infos" class="card-body mt-3 mb-2">
          <div id="general_infos-id">
            <div class="card">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="step4-inf"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>step4_how</span><br>
                  <span translate>step4_how_</span><br><br>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-1">
            <div class="card-header">
              <a class="collapsed card-link button-font"
                 data-toggle="collapse"
                 href="#collapseThree"
                 (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                <span translate>cookies</span>
              </a>
            </div>
            <div id="collapseThree-inf" class="collapse"
                 [ngClass]="{'show' : col_show_3}"
                 data-parent="#accordion">
              <div class="card-body font-card-body">
                <p translate>cook_det</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="contact_adress" class="card-body mt-3 mb-2">
          <div id="adress-id">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  <span translate>car_password</span>
                </a>
              </div>
              <div id="collapseTwo-add" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>car_pawd_desc</span>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="collapseOne-add"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>stpa</span><br>
                  <span translate>stpb</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cookies</span>
                </a>
              </div>
              <div id="collapseThree-add" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cook_det</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="justificatifs" class="card-body mt-3 mb-2">
          <div id="justificatifs-id">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  <span translate>doc_carr</span>
                </a>
              </div>
              <div id="collapseTwo-just" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>doc_taille</span>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="collapseOne-just"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>docsta</span><br>
                  <span translate>docstb</span><br>
                  <span translate>docstc</span><br><br>
                  <span class="text-danger font-weight-bold" translate>attention<span> !</span></span><br><br>
                  <p translate><span class="font-weight-bold">. </span>attention_1</p><br>
                  <p translate><span class="font-weight-bold">. </span>attention_2</p><br>
                  <p translate><span class="font-weight-bold">. </span>attention_3</p>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cookies</span>
                </a>
              </div>
              <div id="collapseThree-just" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cook_det</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</body>

<footer class="_footer" style="background-color: #2c292f">
  <div class="container">
    <div class="row justify-content-center custom-foo">
      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-light">
            <img src="assets/images/logo.png"
                 class="mr-1 mb-1"
                 style="width: 43px; height: 23px"/><span class="button-font">&copy;2020 A2A Expertise</span>
          </p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <div>
            <p><i class="fa fa-envelope mt-1 mr-2"></i><a
              href="mailto:consulats@ambaguinee.org" style="color: #1587bd">consulats@ambaguinee.org</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-3 pt-1">
          <a href="https://www.facebook.com/A2A-Expertise-2065223603792416/"><i
            class="fa fa-facebook fa-2x text-primary mx-3"></i></a>
          <a href="https://www.linkedin.com/company/a2a-expertise"><i class="fa fa-linkedin fa-2x text-danger mx-3"></i></a>
          <a href="https://twitter.com/"><i class="fa fa-twitter fa-2x text-info mx-3"></i></a>
          <a href="https://www.youtube.com/watch?v=l21QDxgQjSc"><i class="fa fa-youtube fa-2x text-danger mx-3"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--UPLOAD DOC-->
<div bsModal #chooseFile="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content cus_modal-style">
      <div class="modal-header">
        <h6 class="modal-title pull-left">
          <span translate>popa_head</span>
        </h6>
      </div>
      <div class="modal-body">
        <form class="mt-3" #justForm="ngForm" (ngSubmit)="justForm.valid" novalidate>
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="docName" translate>
                  doc_name
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <select [(ngModel)]="user_informations.docCode"
                        name="docName"
                        class="form-control"
                        id="docName"
                        type="text"
                        #docName="ngModel"
                        required
                        [ngClass]="{ 'is-invalid': docName.touched && docName.errors?.required }">
                  <option value="SGN00029" translate>passport</option>
                  <option value="SGN00028" translate>idcart</option>
                  <option value="SGN00030" translate>cart_con</option>
                </select>
                <span class="text-danger font-col"
                      *ngIf="docName.touched && docName.errors?.required" translate>
                    fich_req
              </span>
              </div>

            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="docNum" translate>
                  doc_numm
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <input [(ngModel)]="user_informations.docNum"
                       name="docNum"
                       class="form-control"
                       id="docNum"
                       placeholder="{{ 'doc_numm' | translate }}"
                       type="text"
                       #docNum="ngModel"
                       required
                       [ngClass]="{ 'is-invalid': docNum.touched && docNum.errors?.required }">
                <span class="text-danger font-col"
                      *ngIf="docNum.touched && docNum.errors?.required" translate>
                          num_req
                    </span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="dateDeliv" translate>
                  date_dell
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <input [(ngModel)]="user_informations.dateDeliv"
                       name="dateDeliv"
                       class="form-control"
                       id="dateDeliv"
                       placeholder="{{ 'date_dell' | translate }}"
                       type="date"
                       #dateDeliv="ngModel"
                       required
                       [ngClass]="{ 'is-invalid': (dateDeliv.touched && dateDeliv.errors?.required)
                                                  || (dateDeliv.value != '' && dateDeliv.value > latest_date)}">
                <span class="text-danger font-col"
                      *ngIf="dateDeliv.touched && dateDeliv.errors?.required" translate>
                      del_req
                </span>
                <span class="text-danger font-col" *ngIf="(dateDeliv.value != '' && dateDeliv.value > latest_date)"
                      translate>
                      del_req_<br>
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-4">
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="dateExp" translate>
                  date_expira
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <input [(ngModel)]="user_informations.dateExp"
                       name="dateExp"
                       class="form-control"
                       id="dateExp"
                       placeholder="{{ 'date_expira' | translate }}"
                       type="date"
                       #dateExp="ngModel"
                       required
                       [ngClass]="{ 'is-invalid': dateExp.touched && dateExp.errors?.required}">
                <span class="text-danger font-col"
                      *ngIf="dateExp.touched && dateExp.errors?.required" translate>
                      expi_req
                </span>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="fileBase64" translate>
                  add_file
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <input name="fileBase64"
                       id="fileBase64"
                       ngModel
                       type="file"
                       accept="image/*, application/pdf"
                       class="form-control form-control-alternative"
                       #fileBase64="ngModel"
                       (change)="uploadFile($event)"
                       required
                       [ngClass]="{ 'is-invalid': (fileBase64.touched && fileBase64.errors?.required) || !uploadFileOK }">
                <span class="text-danger font-col"
                      *ngIf="fileBase64.touched && fileBase64.errors?.required" translate>
                      file_req
                </span>
                <span class="text-danger font-col"
                      *ngIf="!uploadFileOK" translate>
                      file_max_t
                </span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <hr class="mb-3 mt-2">
            <button type="button"
                    class="btn btn-danger btn-sm"
                    (click)="chooseFile.hide()">
              <i class="fa fa-window-close mr-2" aria-hidden="true"></i>
              <span translate>handle</span>
            </button>
            <button type="submit"
                    [disabled]="!justForm.valid || !uploadFileOK"
                    class="btn btn-success btn-sm float-right"
                    (click)="chooseFile.hide();justResultSucc=false;justErr=true">
              <i class="fa fa-check-square-o mr-2" aria-hidden="true"></i>
              <span translate>val</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- image cropper-->

<div bsModal #chooseImage="bs-modal" class="modal fade cus_modal-style" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content cus_modal-style">
      <div class="modal-header">
        <h6 class="modal-title text-center" translate>
          phot_head
        </h6>
      </div>
      <div class="modal-body">
        <div *ngIf="showContentPopup" class="row mt-3">
          <div class="text-center col-md-6">
            <h6 class="text-muted" translate>img_rec</h6>
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="false"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
          </div>
          <div class="text-center col-md-6 mt-1">
            <h6 class="text-muted" translate>aper</h6>
            <!---  <table class="table table-bordered">
              <thead>
              <tr>
                <th scope="col">
                  <i class="fa fa-wrench" aria-hidden="true"></i>
                </th>
                <th scope="col" translate>lar</th>
                <th scope="col" translate>haut</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <strong translate>act_val</strong>
                </td>
                <td [ngClass]="((imgeWidth < 500) || (imgeWidth > 1250)) ?'text-danger':'text-success'">
                  <strong>{{ imgeWidth }} pixels</strong>
                </td>
                <td [ngClass]="((imgeHeight < 600 ) || (imgeHeight > 1250)) ?'text-danger':'text-success'">
                  <strong>{{ imgeHeight }} pixels</strong>
                </td>
              </tr>
              <tr>
                <td><strong translate>val_acc</strong></td>
                <td class="text-success"><strong>[500 - 1250] pixels</strong></td>
                <td class="text-success"><strong>[600 - 1250] pixels</strong></td>
              </tr>
              </tbody>
            </table>--->
            <div class="container">
              <img class="img_resize ml-auto mr-auto"
                   [src]="croppedImage"/>
              <div class="centered_cir">
                <span class="circle_visage">
                </span>
              </div>
              <div class="centered_hr">
                <hr class="hr_style">
                <span class="text-danger pt-3 pb-3" translate>eye_pos</span>
                <hr class="hr_style">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!showContentPopup" class="text-center text-danger">
          <h6 translate>file_max_t</h6>
        </div>
        <div class="mt-4">
          <hr class="mb-3 mt-2">
          <button type="button"
                  class="btn btn-danger btn-sm"
                  (click)="chooseImage.hide()">
            <i class="fa fa-window-close mr-2" aria-hidden="true"></i>
            <span translate>handle</span>
          </button>
          <button [disabled]="!showContentPopup" type="button"
                  class="btn btn-success btn-sm float-right"
                  (click)="chooseImage.hide();justPhotoSucc=false;justPhotoErr=true">
            <i class="fa fa-check-square-o mr-2" aria-hidden="true"></i>
            <span translate>val</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--email adress valid-->

<ng-template #accExist>
  <div class="modal-header">
    <h6 class="modal-title text-muted text-center m-auto" translate>{{ accountError }}</h6>
  </div>
  <div class="modal-body modal-style">
    <div class="row">
      <div class="ml-auto mr-2">
        <button class="btn btn-primary btn-sm btn-block"
                (click)="modalRef.hide();" translate>
          close
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalErr>
  <div class="modal-body modal-style-err">
    <div class="row">
      <div class="col-lg-10">
        <h6 class="modal-title pull-left cus_col text-danger" translate>
          {{ errMessage }} <br>
        </h6>
      </div>
      <div class="col-lg-2">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<div bsModal #modalConfirmResidence="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-danger m-auto">
          <i class="fa fa-exclamation-triangle text-danger mr-2"></i>
          <span translate>confheader</span>
        </h6>
      </div>
      <div class="modal-body">
        <p class="m-auto pt-3 pb-3">
          <span translate>residConfmessage</span><br><br>
          <span translate >residenceConfirm</span> {{confirmResidenceName}}?<br>
        </p>
      </div>
      <div class="modal-body">
        <p class="font-card-body text-center"></p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideModalConfirmResidence();" translate>
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</html>
