import {Injectable, OnInit} from '@angular/core';
import {CrudService} from "../../shared/crud.service";
import {Observable} from "rxjs";
import { API_URLS } from '../../app-config/api.url.config';
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RegisterService implements CrudService, OnInit {

  codeUrl: any;
  verifuUrl: any;
  ambUrl: any;
  checkMobileUrl: any;


  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  addCode(email, ekaidiRecaptcha): Observable<any> {
    this.codeUrl = API_URLS.API_URLS_SEND + email + '/code';
    return  this.httpClient.post(this.codeUrl, ekaidiRecaptcha);
  }

  verifyUsername(username): Observable<any> {
    this.verifuUrl = API_URLS.API_URLS_VERIFY + username + '/verify';
    return  this.httpClient.get(this.verifuUrl);
  }

  checkMobile(country: string, mobile: string): Observable<any> {
    this.checkMobileUrl = API_URLS.API_URLS_CONTACTS + '/check-mobile';
    // Initialize Params Object
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append('country', country);
    params = params.append('mobile', mobile);
    return  this.httpClient.get(this.checkMobileUrl, {params: params});
  }

  validateCode(_validation): Observable<any> {
    return  this.httpClient.put(API_URLS.API_URLS_VALIDATE, _validation);
  }

  add(userData): Observable<any> {
    return this.httpClient.post(API_URLS.API_URLS_SENDDATA, userData);
  }

  delete(id: number): Observable<any> {
    return undefined;
  }

  getAll(): Observable<any> {
    return undefined;
  }

  update(item): Observable<any> {
    return undefined;
  }
}
