import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'e-kaidi';

  ngOnInit() {
    let lang;
    lang = localStorage.getItem('ekaidiLang');
    if (lang === null) {
      localStorage.setItem('ekaidiLang', 'FR');
    }
  }
}
