<html>
<header #header>
  <nav class="navbar navbar-expand-lg navbar-light Header">
    <div>
      <img class="ekaidi_img" src="assets/images/log_gui.png">
    </div>
    <div>
      <p class="log_text text-center ml-3 mt-3">
        <span class="log_text_1" translate>log_1</span><br>
        <span class="log_text_2" translate>log_2</span><br>
        <span class="log_text_3" translate>log_3</span><br>
      </p>
    </div>
    <button class="navbar-toggler" type="button" (click)="show=!show">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{'show' : show}">
      <ul class="navbar-nav ml-auto">
        <div class="nav-link my-2 my-md-0 mr-md-5">
          <a class="Header-link p-2" (click)="navigateSection(section_services)" translate>m_service</a>
        </div>
        <div class="nav-link my-2 my-md-0 mr-md-5">
          <a class="Header-link p-2" (click)="onRedirectToFaq()" translate>m_guide</a>
        </div>
        <div class="nav-link my-2 my-md-0 mr-md-5">
          <a class="Header-link p-2" (click)="onRedirectToAppointment()" translate>m_appointment</a>
        </div>
        <div class="nav-link my-2 my-md-0 mr-5">
          <a class="Header-link p-2" (click)="onRedirectLogin()" translate>
            <i class="fa fa-user-circle mr-2"></i>
            m_espace
          </a>
        </div>
        <div class="ml-4 mr-4">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-tr dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              {{ selectedLang }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item lang" (click)="changerLangueFR()">FR</a>
              <a class="dropdown-item lang" (click)="changerLangueEN()">EN</a>
            </div>
          </div>
        </div>

      </ul>
    </div>
  </nav>
</header>
<body class="marg">
<div class="container">
  <div class="m-3">
    <div class="SectionHead pt-4">
      <div class="row">
        <div class="col-md-10">
          <h1 class="SectionHead-title SectionHead-title--darker pr-4">
            <strong translate>b_bienvenue <span
              style="color: #1587bd">E-KAIDI !</span>
            </strong>
          </h1>
        </div>
        <div class="col-md-2 text-center">
          <img class="ekaidi" src="assets/images/ekaidi_max.png">
        </div>
      </div>

      <h5 class="SectionHead-title-1 SectionHead-title--darker mt-4" translate>
        b_sol
      </h5>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-4 mt-3">
        <div class="form-group text-center">
          <label class="text-muted" translate>label_btn_cpt</label>
          <button class="btn btn-primary btn-lg btn-block mt-2 rect_button"
                  (click)="onRedirectLogin()">
            <i class="fa fa-lock mr-2" aria-hidden="true"></i>
            <span class="button-font" translate>cont_btn_cpt</span>
          </button>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="form-group text-center">
          <label class="text-muted" translate>label_btn_sign</label>
          <button class="btn btn-lg btn-danger btn-block mt-2 rect_button"
                  (click)="onRedirectRegister()">
            <i class="fa fa-user-plus mr-2" aria-hidden="true"></i>
            <span class="button-font" translate>cont_btn_sign</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="pt-5" id="services">
  <div class="our-services mt-4 mb-4 pt-4">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 mx-auto text-center">
          <div class="section-title mb-5">
            <h4 class="SectionHead-title SectionHead-title-cus" translate>ekaidi_reason</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <!-- Single Service -->
          <div class="single-service">
            <i class="fa fa-user"></i>
            <h4 translate>simple </h4>
            <p translate>simple_val</p>
          </div>
        </div>
        <div class="col-lg-3">
          <!-- Single Service -->
          <div class="single-service">
            <i class="fa fa-hand-o-up"></i>
            <h4 translate>prox</h4>
            <p translate>prox_val</p>
          </div>
        </div>
        <div class="col-lg-3">
          <!-- Single Service -->
          <div class="single-service">
            <i class="fa fa-hourglass-end"></i>
            <h4 translate>gain</h4>
            <p translate>gain_val</p>
          </div>
        </div>
        <div class="col-lg-3">
          <!-- Single Service -->
          <div class="single-service">
            <i class="fa fa-key"></i>
            <h4 translate>sec</h4>
            <p translate>sec_val</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container marg_top">
    <div #section_how class="row">
      <div class="col-xl-6 mx-auto text-center">
        <div class="section-title mb-5">
          <h4 class="SectionHead-title SectionHead-title--darker" translate>ek_exp</h4>
        </div>
      </div>
    </div>
    <div class="text-center">
      <video controls
             poster="assets/images/video-img.png">
        <source src="https://a2a-expertise.com/wp-content/uploads/2018/12/Mes-Démarches-KAIDI.mp4"
                type="video/mp4">
        <source src="https://a2a-expertise.com/wp-content/uploads/2018/12/Mes-Démarches-KAIDI.ogg"
                type="video/ogg">
        <source src="https://a2a-expertise.com/wp-content/uploads/2018/12/Mes-Démarches-KAIDI.webm"
                type="video/webm">
      </video>
    </div>
  </div>
</section>
<!-- Services section -->
<section #section_services class="our-services marg_top" id="our-services">
  <div class="container">
    <div class="text-center marg_top_ser">
      <h6 class="SectionHead-title SectionHead-title-cus" translate>all_serv</h6>
      <p class="SectionHead-title-cus h5 ml-2 mt-2" translate>serv_dec</p>
    </div>
    <div class="marg_bot_ser marg_top_ser">
      <div class="row">
        <div class="col-lg-4 mb-3">
          <div class="card height-card border-0 shadow rounded-xs">
            <div class="card-body">
              <div class="text-center">
                <img class="logo-services" src="assets/images/conkeite/Kaidi.png">
              </div>
              <p class="sapn_txt" translate>kaidi_2</p>
              <p class="sapn_txt" translate>kaidi_3</p>
              <p class="sapn_txt" translate>kaidi_4</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card height-card border-0 shadow rounded-xs">
            <img class="card-img-top m-auto" src="assets/images/conkeite/conkeite.png" alt="Card image cap">
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card height-card border-0 shadow rounded-xs">
            <div class="card-body">
              <div class="text-center">
                <img class="logo-services" src="assets/images/conkeite/ged.png">
              </div>
              <p class="sapn_txt" translate>ged_1</p>
              <p class="sapn_txt" translate>ged_2</p>
              <p class="sapn_txt" translate>ged_3</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="card height-card border-0 shadow rounded-xs">
            <div class="card-body">
              <div class="text-center">
                <img class="logo-services" src="assets/images/conkeite/visa.png">
              </div>
              <p class="sapn_txt" translate>visa_1</p>
              <p class="sapn_txt" translate>visa_2</p>
              <p class="sapn_txt" translate>visa_3</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card height-card border-0 shadow rounded-xs">
            <div class="card-body">
              <div class="text-center">
                <img class="logo-services" src="assets/images/conkeite/qms.png">
              </div>
              <p class="sapn_txt" translate>qms_1</p>
              <p class="sapn_txt" translate>qms_2</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="card height-card border-0 shadow rounded-xs">
            <div class="card-body">
              <div class="text-center">
                <img class="logo-services" src="assets/images/conkeite/meet.png">
              </div>
              <p class="sapn_txt" translate>meet_1</p>
              <p class="sapn_txt" translate>meet_2</p>
              <p class="sapn_txt" translate>meet_3</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container marg_top" #section_help>
  <div class="custom-res">
    <h1 class="SectionHead-title SectionHead-title--darker pr-4">
      <span><strong translate>guide</strong></span>
    </h1>
    <button class="btn btn-primary btn-lg mt-5 mb-5" (click)="onRedirectToFaq()">
      <span class="button-font-faq" translate>faq</span>
      <i class="ml-3 fa fa-arrow-right"></i>
    </button>
  </div>
</section>
</body>

<footer class="mt-5" style="background-color: #2c292f">
  <div class="container">
    <div class="row justify-content-center custom-foo">
      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-light">
            <img src="assets/images/logo.png"
                 class="mr-1 mb-1"
                 style="width: 43px; height: 23px"/>
            <a href="https://a2a-expertise.com" target="_blank" style="color: #1587bd">a2a-expertise.com</a>
          </p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <div>
            <p><i class="fa fa-envelope  mx-2"></i><a
              href="mailto:consulats@ambaguinee.org" style="color: #1587bd">consulats@ambaguinee.org</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-3">
          <a href="https://www.facebook.com/A2A-Expertise-2065223603792416/" target="_blank"><i
            class="fa fa-facebook fa-2x text-primary mx-3"></i></a>
          <a href="https://www.linkedin.com/company/a2a-expertise" target="_blank"><i
            class="fa fa-linkedin fa-2x text-danger mx-3"></i></a>
          <a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter fa-2x text-info mx-3"></i></a>
          <a href="https://www.youtube.com/watch?v=l21QDxgQjSc" target="_blank"><i
            class="fa fa-youtube fa-2x text-danger mx-3"></i></a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center custom-foo">
      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-muted">
            <a class="hov btn btn-link" (click)="scrollToTop(header)" translate>
              home
            </a>|
            <a class="hov btn btn-link" target="_blank" translate>
              part
            </a>
          </p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-muted"><a class="btn btn-link"> &copy;2020 A2A Expertise</a></p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-muted"><a class="hov btn btn-link" (click)="openNewTabPdf()" translate>
            cond_gen</a></p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button class="btn btn-primary" (click)="scrollToTop(header)">
    <i class="fa fa-arrow-up" aria-hidden="true"></i>
  </button>
</div>
<div *ngIf="show_condition" class="conditionA2a">
  <p class="cent" translate>cond_txt
    <a class="condition_redirect" (click)="openNewTabPdf()" translate>con_ut</a>. <span
      class="ml-3 btn btn-light btn-sm" (click)="hideCondition()">OK</span>
  </p>
</div>
<div bsModal #emailModal="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-muted">
        <h6 class="text-center m-auto" translate>emailMessage</h6>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideEmailModal();" translate>
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</html>
