<header>
  <nav class="navbar navbar-expand-lg navbar-light Header">
    <h5 class="my-0 resp-mar font-weight-normal">
      <i class="fa fa-long-arrow-left cursor-point"
         data-toggle="tooltip" title="Acceuil"
         (click)="onRedirectHome()"></i></h5>
    <div>
      <img class="ekaidi_img" src="assets/images/ekaidi_max.png">
    </div>
    <ul class="nav navbar-nav ml-auto">
      <div class="nav-link my-2 my-md-0 mr-md-3">
        <i class="fa fa-info-circle text-muted"
           tooltip="{{ 'helpInf' | translate }}"
           [adaptivePosition]="true"
           placement="left"
           aria-hidden="true"></i>
      </div>
    </ul>
  </nav>
</header>
<body>
<div class="marg text-center mt-3 pb-4" style="background: #1587bd; color: white">
  <h1  translate>guide</h1>
  <h5 class="mt-3" translate>rep_q</h5>
  <img class="img_id m-3" src="assets/images/CC_V.png">
  <img class="img_id m-3" src="assets/images/CC_R.png">
</div>
<div class="container mt-5">
  <h5 class="text-muted pb-2" translate>q_gen</h5>
  <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a class="collapsed card-link button-font"
           data-toggle="collapse"
           href="#collapseTwo"
           (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;col_show_5=false;col_show_4=false">
          <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
          <span translate>ekai_q</span>
        </a>
      </div>
      <div id="collapseTwo" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
        <div class="card-body font-card-body" translate>
          eq_exp
          <br>
          <br>
          <span translate>video</span> <br><br>
          <a class="m-auto" href="https://a2a-expertise.com/wp-content/uploads/2018/12/Mes-Démarches-KAIDI.mp4"
               target="_blank">
            <img class="vid_img" src="assets/images/video-img.png">
          </a>
        </div>
      </div>
    </div>

    <div class="card mt-1">
      <div class="card-header">
        <a class="collapsed card-link button-font"
           data-toggle="collapse"
           href="#collapse_"
           (click)="col_show_5=!col_show_5; col_show_3=false; col_show_1=false;col_show_4=false;col_show_2=false;">
          <span class="mr-2">{{ col_show_5 ? '-' : '+'}}</span>
          <span translate>ekai_dem</span>
        </a>
      </div>
      <div id="collapse_" class="collapse" [ngClass]="{'show' : col_show_5}" data-parent="#accordion">
        <div class="mt-3 border-0 text-center">
          <video controls width="750"
                 poster="assets/images/video-img.png">
            <source src=" https://ca.ambaguinee.org/wp-content/uploads/2021/12/E-KAIDI_TutorielCitoyen.mp4"
                    type="video/mp4">
          </video>
        </div>
        <div class="ml-3 mt-3 mb-2">
          <span translate>ekai_demDesc</span><br>
          <span class="btn_redirect btn btn-link" (click)="openNewTabPdf()" translate>guide_ut</span>
        </div>
      </div>
    </div>
    <div class="card mt-1">
      <div class="card-header">
        <a class="collapsed card-link button-font"
           data-toggle="collapse"
           href="#collapseThree"
           (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;col_show_4=false;col_show_5=false;">
          <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
          <span translate>forg_pwd</span>
        </a>
      </div>
      <div id="collapseThree" class="collapse"
           [ngClass]="{'show' : col_show_3}"
           data-parent="#accordion">
        <div class="card-body font-card-body">
          <p translate>explication</p>
        </div>
      </div>
    </div>
    <div class="card mt-1">
      <div class="card-header">
        <a class="collapsed card-link button-font"
           data-toggle="collapse"
           href="#collapseFor"
           (click)="col_show_4=!col_show_4; col_show_2=false; col_show_1=false;col_show_3=false;col_show_5=false">
          <span class="mr-2">{{ col_show_4 ? '-' : '+'}}</span>
          <span translate>eq_do</span>
        </a>
      </div>
      <div id="collapseFor" class="collapse"
           [ngClass]="{'show' : col_show_4}"
           data-parent="#accordion">
        <div class="card-body font-card-body">
          <p translate>eq_is</p>
          <ul>
            <li translate>li0
              <ul>
                <li translate>li1</li>
                <li translate>li2</li>
                <li translate>li3</li>
                <li translate>li4</li>
                <li translate>li5</li>
                <li translate>li6</li>
                <li translate>li7</li>
                <li translate>li8</li>
                <li translate>li9</li>
              </ul>
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li10
              <ul>
                <li translate>li11
                </li>
              </ul>
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li12
              <ul>
                <li translate>tel_n</li>
                <li translate>tablette</li>
                <li translate>ord</li>
              </ul>
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li13
            </li>
            <li translate>li14
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li15
              <ul>
                <li translate>li16
                </li>
                <li translate>li17</li>
              </ul>
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li18
              <ul>
                <li translate>li19</li>
                <li translate>li20
                </li>
              </ul>
            </li>
            <hr class="mt-3 mb-3">
            <li translate>li21
              <ul>
                <li translate>li22
                </li>
                <li translate>li23
                </li>
                <li translate>li24</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card mt-1">
      <div class="card-header button-font">
        <a class="card-link"
           data-toggle="collapse"
           (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;col_show_4=false;col_show_5=false"
           href="#collapseOne">
          <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
          <span translate>pas_rep</span>
        </a>
      </div>
      <div id="collapseOne"
           class="collapse"
           [ngClass]="{'show' : col_show_1}"
           data-parent="#accordion">
        <div class="card-body font-card-body">
          <p><span translate> faq_nn </span>
            <a href="consulats@ambaguinee.org">consulats@ambaguinee.org</a> <span translate> trans </span></p>
          <div>
            <li translate>infoss
            </li>
            <li translate>add_p</li>
            <li translate>add_pa</li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>
<footer class="mt-5" style="background-color: #2c292f">
  <div class="container">
    <div class="row justify-content-center custom-foo">
      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-light">
            <img src="assets/images/logo.png"
                 class="mr-1 mb-1"
                 style="width: 43px; height: 23px"/><span class="button-font">&copy;2020 A2A Expertise</span>
          </p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <div>
            <p><i class="fa fa-envelope  mx-2"></i><a
              href="mailto:consulats@ambaguinee.org">consulats@ambaguinee.org</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <a href="https://www.facebook.com/A2A-Expertise-2065223603792416/"><i
            class="fa fa-facebook fa-2x text-primary mx-3"></i></a>
          <a href="https://www.linkedin.com/company/a2a-expertise"><i class="fa fa-linkedin fa-2x text-danger mx-3"></i></a>
          <a href="https://twitter.com/"><i class="fa fa-twitter fa-2x text-info mx-3"></i></a>
          <a href="https://www.youtube.com/watch?v=l21QDxgQjSc"><i class="fa fa-youtube fa-2x text-danger mx-3"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>
