import {TranslateService} from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  succSub: Boolean = false;
  selectedLang: string = localStorage.getItem('ekaidiLang');

  constructor(private translate: TranslateService) {
    translate.setDefaultLang(this.selectedLang.toLowerCase());
  }

  onChangeLanguage(language: any) {
    if (language === 'fr') {
      this.translate.use(language);
      localStorage.setItem('ekaidiLang', 'FR');
    } else {
      this.translate.use(language);
      localStorage.setItem('ekaidiLang', 'EN');
    }
  }
}
