<html class="back_col">
<header>
  <nav class="navbar navbar-expand-lg navbar-light Header">
    <h5 class="my-0 resp-mar font-weight-normal">
      <i class="fa fa-long-arrow-left cursor-point"
         data-toggle="tooltip" title="Acceuil"
         (click)="onRedirectHome()"></i></h5>
    <div>
      <img class="ekaidi_img" src="assets/images/ekaidi_max.png">
    </div>
    <ul class="nav navbar-nav ml-auto">
      <div class="nav-link my-2 my-md-0 mr-md-3">
        <i class="fa fa-info-circle text-muted cursor-point"
           tooltip="{{ 'help' | translate }}"
           [adaptivePosition]="true"
           placement="top"
           aria-hidden="true"
           (click)="openModalHelp(modalHelp)"></i>
      </div>
    </ul>
  </nav>
</header>
<body [ngClass]="(isCalendar || recapitulatif || userInfo) ? 'container-fluid' : 'container'" class="marg back_col">
<div>
  <p *ngIf="!isCalendar && !recapitulatif && !userInfo">
    <span class="font-col" (click)="onRedirectHome()" translate>bred_appointment</span>
    <span class="_font-col text-muted" translate>bred_appointment_</span>
  </p>
  <div class="row justify-content-center">
    <div [ngClass]="(isCalendar || recapitulatif || userInfo) ? col_12 : col_7" class="order-xl-1 mb-2 mb-xl-0">
      <p *ngIf="isCalendar || recapitulatif || userInfo">
        <span class="font-col" (click)="onRedirectHome()" translate>bred_appointment</span>
        <span class="_font-col text-muted" translate>bred_appointment_</span>
      </p>
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>m_appointment</h5>
        </div>
        <div class="mb-2">
          <progressbar [max]="max" [value]="dynamic" [type]="type">
            <span class="text-nowrap">{{dynamic}} / {{max}}</span>
          </progressbar>
        </div>
        <div *ngIf="showAlert" class="m-2 alert alert-info alert-dismissible fade show text-center" role="alert">
          <span class="text-muted jsut_text" translate>send_code</span>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body ml-4 mr-4">
          <h3 class="text-center text-muted mb-4">{{ title | translate }}</h3>
          <!--Vérification 1/1-->
          <div *ngIf="_verification">
            <form #form="ngForm" (ngSubmit)="form.valid" novalidate>
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="_email" translate>
                  email_add
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="email"
                         name="email"
                         id="_email"
                         [(ngModel)]="user_informations.email"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (email.touched || form.submitted) && email.errors?.required && email.invalid || email.errors?.pattern || email_req }"
                         #email="ngModel"
                         placeholder="{{ 'email_add' | translate }}"
                         required
                         pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                         (input)="email_req=false">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(email.touched || form.submitted) && email.errors?.required || email_req" translate>
                                email_req
            </span>
                <span class="text-danger font-col"
                      *ngIf="email.errors &&(email.touched || email.dirty)">
                  <p [hidden]="!email.errors?.pattern" translate>
                          email_inv
                   </p>
            </span>
              </div>
              <hr class="mt-4">
              <div class="mt-4 text-center">
                <div class="row justify-content-center">
                  <ngx-recaptcha2 [(ngModel)]="ekaidiRecaptcha"
                                  name="ekaidiRecaptcha"
                                  (reset)="handleReset()"
                                  (expire)="handleExpire()"
                                  [siteKey]="siteKey"
                                  (load)="handleLoad()"
                                  (success)="handleSuccess($event)"
                                  [hl]="langue">
                  </ngx-recaptcha2>
                </div>
                <span *ngIf="recapError" class="text-danger font-col mt-1" translate>
                                recapErr
                </span>
              </div>
              <hr class="mt-4">
              <div class="text-center">
                <div class="form-check mt-4">
                  <input [(ngModel)]="justifAccept" name="checkCheckbox" type="checkbox"
                         class="pointer_con form-check-input" id="exampleCheck1"
                         (change)="checkCheckbox($event)">
                  <label class="form-check-label" for="exampleCheck1">
                    <p class="font-card-body pointer_con" translate>congeneral
                    </p>
                  </label>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <button type="button"
                          [disabled]="myRecaptcha || email.invalid || !justifAccept"
                          class="btn btn-block"
                          [ngClass]="{ 'btn-secondary' : (myRecaptcha || email.invalid || !justifAccept), 'btn-primary' : (myRecaptcha || !email.invalid || justifAccept)}"
                          (click)="onReceiveCode()">
                    <span [hidden]="spinner" class="spinner-border spinner-border-sm mr-2" role="status"
                          aria-hidden="true" translate></span>
                    <span [hidden]="btn_send" translate>code_rec</span>
                    <span [hidden]="!btn_send" translate>send</span>
                    <i [hidden]="!btn_send" class="fa fa-check ml-3" aria-hidden="true"></i>
                    <i [hidden]="btn_send" class="fa fa-paper-plane ml-3" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        hidden
                        class="btn pull-right"
                        [ngClass]="{ 'btn-secondary' : nextStep, 'btn-primary' : !nextStep}"
                        [disabled]="nextStep">
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--Vérification 2/2-->
          <div *ngIf="verification">
            <form #form="ngForm" (ngSubmit)="form.valid && nextUserInfos(form.value)" novalidate>
              <div class="form-group">
                <label class="font-col-label  form-control-label text-muted" for="email" translate>
                  email_add
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="email"
                         name="email"
                         id="email"
                         [(ngModel)]="user_informations.email"
                         class="form-control"
                         disabled>
                </div>
              </div>

              <div class="form-group  mt-4">
                <label class="font-col-label  form-control-label text-muted" for="code" translate>
                  code_verif
                  <span class="text-danger font-col">
                    *
                  </span>
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="rect input-group-text">
                      <i class="fa fa-envelope-open-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <input type="text"
                         name="code"
                         id="code"
                         [(ngModel)]="user_informations.code"
                         class="form-control"
                         [ngClass]="{ 'is-invalid': (code.touched || form.submitted) && code.errors?.required || respErrCode }"
                         #code="ngModel"
                         placeholder="{{ 'code_verif' | translate }}"
                         required
                         (input)="respErrCode=false;">
                </div>
                <span class="text-danger font-col"
                      *ngIf="(code.touched || form.submitted) && code.errors?.required" translate>
                                err_code_req<br>
                </span>
                <span class="text-danger font-col" *ngIf="respErrCode" translate>
                                valid_code
                </span>

              </div>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="_previousVerification()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="nextStep && myRecaptcha">
                  <span [hidden]="spinner" class="spinner-border spinner-border-sm mr-2" role="status"
                        aria-hidden="true" translate></span>
                  <span translate>next</span>
                  <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--User infos-->
          <div *ngIf="userInfo">

            <!--BENEFICIAIRE-->
            <div *ngIf="dossiers.length!==0" class="mt-3 mb-4">
              <div *ngFor="let user of dossiers; let indexOfelement=index;">
                <div class="row">
                  <div class="col-7">
                    <h6 class="text-muted cursor-point mt-2 mb-2" (click)="user.isCollapsed = !user.isCollapsed"
                        [attr.aria-expanded]="!user.isCollapsed">
                      <i *ngIf="!user.isCollapsed" class="fa fa-folder-open-o mr-2"></i>
                      <i *ngIf="user.isCollapsed" class="fa fa-folder-o mr-2"></i>
                      <span *ngIf="indexOfelement === 0" translate>infg </span>
                      <span *ngIf="indexOfelement !== 0" translate>infgb </span><span
                      class="text-primary"> {{ user.applicant.firstName + ' ' + user.applicant.lastName }}</span>
                    </h6>
                  </div>
                  <div class="col-5">
                    <i [hidden]="dossiers.length===1" (click)="removeBen(indexOfelement)"
                       class="fa fa-trash-o mt-2 _cursor-point"
                       aria-hidden="true"></i>
                  </div>
                </div>
                <div [collapse]="user.isCollapsed" [isAnimated]="true">
                  <div class="card p-3">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bprenom" translate>
                            prenom
                            <span class="text-danger font-col">
                           *
                       </span>
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="bprenom"
                                   id="bprenom"
                                   [(ngModel)]="user.applicant.firstName"
                                   class="form-control"
                                   [disabled]="benifList"
                                   required
                                   [ngClass]="{ 'is-invalid': (bprenom.touched || formGenInfo.submitted) && bprenom.errors?.required }"
                                   #bprenom="ngModel"
                                   placeholder="{{ 'prenom' | translate }}">
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bprenom.touched || formGenInfo.submitted) && bprenom.errors?.required"
                                translate>
                               pren_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bnom" translate>
                            nom_nec
                            <span class="text-danger font-col">
                    *
                  </span>
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="bnom"
                                   id="bnom"
                                   [disabled]="benifList"
                                   [(ngModel)]="user.applicant.lastName"
                                   class="form-control"
                                   placeholder="{{ 'nom_nec' | translate }}"
                                   [ngClass]="{ 'is-invalid': (bnom.touched || formGenInfo.submitted) && bnom.errors?.required }"
                                   #bnom="ngModel"
                                   required>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bnom.touched || formGenInfo.submitted) && bnom.errors?.required" translate>
                               nom_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bgenre" translate>
                            gen
                            <span class="text-danger font-col">
                        *
                       </span>
                          </label>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="bgenre"
                                    id="bgenre"
                                    [disabled]="benifList"
                                    [(ngModel)]="user.applicant.gender"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': (bgenre.touched || formGenInfo.submitted) && bgenre.errors?.required }"
                                    #bgenre="ngModel"
                                    required>
                              <option [ngValue]="'M'" translate>men</option>
                              <option [ngValue]="'F'" translate>wom</option>
                            </select>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bgenre.touched || formGenInfo.submitted) && bgenre.errors?.required"
                                translate>
                               gen_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bdateNaissance" translate>
                            naiss
                            <span class="text-danger font-col">
                         *
                       </span>
                          </label>
                          <div class="input-group">
                            <input type="date"
                                   name="bdateNaissance"
                                   id="bdateNaissance"
                                   [(ngModel)]="user.applicant.birthInfo.day"
                                   class="form-control"
                                   [disabled]="benifList"
                                   #bdateNaissance="ngModel"
                                   required
                                   placeholder="{{ 'naiss' | translate }}"
                                   [ngClass]="{ 'is-invalid': ((bdateNaissance.touched || formGenInfo.submitted) && bdateNaissance.errors?.required)
                                                  || (bdateNaissance.value != '' && bdateNaissance.value > latest_date)}">
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bdateNaissance.touched || formGenInfo.submitted) && bdateNaissance.errors?.required"
                                translate>
                                naiss_req
                       </span>
                          <span class="text-danger font-col"
                                *ngIf="(bdateNaissance.value != '' && bdateNaissance.value > latest_date)" translate>
                                            naiss_inf<br>
                       </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bpays" translate>
                            pay_naiss
                            <span class="text-danger font-col">
                        *
                       </span>
                          </label>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="bpays"
                                    id="bpays"
                                    [disabled]="benifList"
                                    [(ngModel)]="user.applicant.birthInfo.country"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': (bpays.touched || formGenInfo.submitted) && bpays.errors?.required }"
                                    #bpays="ngModel"
                                    required>
                              <option [ngValue]="country.code" *ngFor="let country of countries">
                                {{ country.name }}
                              </option>
                            </select>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bpays.touched || formGenInfo.submitted) && bpays.errors?.required" translate>
                               pay_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bcity" translate>
                            city
                            <span class="text-danger font-col">
                         *
                       </span>
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="bcity"
                                   id="bcity"
                                   [(ngModel)]="user.applicant.birthInfo.city"
                                   class="form-control"
                                   [disabled]="benifList"
                                   placeholder="{{ 'city' | translate }}"
                                   [ngClass]="{ 'is-invalid': (bcity.touched || formGenInfo.submitted) && bcity.errors?.required }"
                                   #bcity="ngModel"
                                   required>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bcity.touched || formGenInfo.submitted) && bcity.errors?.required" translate>
                               city_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bambassade" translate>
                            ambassade
                          </label>
                          <span class="text-danger font-col">
                         *
                       </span>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="bambassade"
                                    id="bambassade"
                                    [(ngModel)]="agency"
                                    #bambassade="ngModel"
                                    disabled
                                    (change)="onchangeAmb($event.target.value)"
                                    [ngClass]="{ 'is-invalid': (bambassade.touched || formGenInfo.submitted) && bambassade.errors?.required }"
                                    required>
                              <option *ngFor="let agency of agencies" class="text-primary" [value]="agency.reference" translate>{{agency.code}}</option>
                            </select>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bambassade.touched || formGenInfo.submitted) && bambassade.errors?.required"
                                translate>
                                ambreq
                       </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="brenewal" translate>
                            demType
                          </label>
                          <span class="text-danger font-col">
                        *
                       </span>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="brenewal"
                                    id="brenewal"
                                    [(ngModel)]="user.renewal"
                                    [disabled]="benifList"
                                    #brenewal="ngModel"
                                    [ngClass]="{ 'is-invalid': (brenewal.touched || formGenInfo.submitted) && brenewal.errors?.required }">
                              <option [ngValue]="false" translate>firstDem</option>
                              <option [ngValue]="true" translate>renewal</option>
                            </select>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(brenewal.touched || formGenInfo.submitted) && brenewal.errors?.required"
                                translate>
                                renouvq
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="duration" translate>
                            duration
                          </label>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="bduration"
                                    id="bduration"
                                    [disabled]="benifList"
                                    [(ngModel)]="user.duration"
                                    #bduration="ngModel"
                                    required
                                    [ngClass]="{ 'is-invalid': (bduration.touched || formGenInfo.submitted) && bduration.errors?.required }">
                              <option [ngValue]="durationfive" translate>five</option>
                              <option [ngValue]="durationten" translate>ten</option>
                            </select>
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bduration.touched || formGenInfo.submitted) && bduration.errors?.required"
                                translate>
                                duration_req
                       </span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bpasseportNum" translate>
                            passeportNum
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="bpasseportNum"
                                   id="bpasseportNum"
                                   [disabled]="benifList"
                                   [(ngModel)]="user.number"
                                   class="form-control"
                                   placeholder="{{ 'passeportNum' | translate }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="bcidnumber" translate>
                            cart_con
                          </label>
                          <span (click)="showMdalCidNum()" class="fa fa-question-circle ml-1 text-muted pointer_con"
                                tooltip="{{'cidloc' | translate}}" placement="top"></span>
                          <div class="input-group">
                            <input type="text"
                                   class="form-control"
                                   name="bcidnumber"
                                   id="bcidnumber"
                                   [disabled]="benifList"
                                   [(ngModel)]="user.cidNumber"
                                   required
                                   #bcidnumber="ngModel"
                                   placeholder="{{ 'cart_con' | translate }}"
                                   [ngClass]="{ 'is-invalid': (bcidnumber.touched || formGenInfo.submitted) && bcidnumber.errors?.required }">
                          </div>
                          <span class="text-danger font-col"
                                *ngIf="(bcidnumber.touched || formGenInfo.submitted) && bcidnumber.errors?.required"
                                translate>
                               cidNumber_req
                       </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="indexOfelement === 0">
                    <h6 class="text-muted mt-4 mb-2" translate>contact</h6>
                    <div class="card p-3">
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="breEmail">
                              Email
                            </label>
                            <div class="input-group">
                              <input type="email"
                                     class="form-control"
                                     name="breEmail"
                                     id="breEmail"
                                     [(ngModel)]="user.applicant.contact.email"
                                     disabled
                                     class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="btelmobile" translate>
                              mobile
                              <span class="text-danger font-col">
                        *
                       </span>
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     class="form-control"
                                     name="btelmobile"
                                     id="btelmobile"
                                     [(ngModel)]="user.applicant.contact.mobile"
                                     [disabled]="true"
                                     #btelmobile="ngModel"
                                     disabled
                                     placeholder="{{ 'mobile' | translate }}">
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="(btelmobile.touched || formGenInfo.submitted) && btelmobile.errors?.required"
                                  translate>
                               mob_req
                       </span>
                            <span class="text-danger font-col"
                                  *ngIf="btelmobile.errors &&(btelmobile.touched || btelmobile.dirty)">
                           <p [hidden]="!btelmobile.errors?.pattern" translate>
                                num_inv
                           </p>
                       </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="btelephone" translate>
                              fixe
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     class="form-control"
                                     name="btelephone"
                                     id="btelephone"
                                     [(ngModel)]="user.applicant.contact.phone"
                                     class="form-control"
                                     [disabled]="true"
                                     #fixetel="ngModel"
                                     placeholder="{{ 'fixe' | translate }}">
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="fixetel.errors &&(fixetel.touched || fixetel.dirty)">
                           <p [hidden]="!fixetel.errors?.pattern" translate>
                                num_inv
                           </p>
                       </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="bpaysres" translate>
                              resCountry
                              <span class="text-danger font-col">
                        *
                       </span>
                            </label>
                            <div class="input-group">
                              <select class="form-control rect"
                                      name="bpaysres"
                                      id="bpaysres"
                                      [(ngModel)]="user.applicant.address.country"
                                      [disabled]="true"
                                      [ngClass]="{ 'is-invalid': (bpaysres.touched || formGenInfo.submitted) && bpaysres.errors?.required }"
                                      #bpaysres="ngModel"
                                      required>
                                <option [ngValue]="country.code" *ngFor="let country of _country">
                                  {{ country.name }}
                                </option>
                              </select>
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="(bpaysres.touched || formGenInfo.submitted) && bpaysres.errors?.required"
                                  translate>
                               resCountry_req
                       </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="bcityres" translate>
                              resCity
                              <span class="text-danger font-col">
                         *
                       </span>
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     name="bcityres"
                                     id="bcityres"
                                     [disabled]="benifList"
                                     [(ngModel)]="user.applicant.address.city"
                                     class="form-control"
                                     [ngClass]="{ 'is-invalid': (bcityres.touched || formGenInfo.submitted) && bcityres.errors?.required }"
                                     #bcityres="ngModel"
                                     required
                                     placeholder="{{ 'city' | translate }}">
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="(bcityres.touched || formGenInfo.submitted) && bcityres.errors?.required"
                                  translate>
                               resCity_req
                       </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="bcodePostal" translate>
                              cod_postal
                              <span class="text-danger font-col">
                            *
                       </span>
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     name="bcodePostal"
                                     id="bcodePostal"
                                     [(ngModel)]="user.applicant.address.zipCode"
                                     class="form-control"
                                     [disabled]="benifList"
                                     [ngClass]="{ 'is-invalid': (bcodePostal.touched || formGenInfo.submitted) && bcodePostal.errors?.required }"
                                     #bcodePostal="ngModel"
                                     required
                                     placeholder="{{ 'cod_postal' | translate}}">
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="(bcodePostal.touched || formGenInfo.submitted) && bcodePostal.errors?.required"
                                  translate>
                               code_req
                       </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="bcomplementAdd"
                                   translate>
                              comp_add_1
                              <span class="text-danger font-col">
                            *
                       </span>
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     name="bcomplementAdd"
                                     id="bcomplementAdd"
                                     [(ngModel)]="user.applicant.address.street"
                                     class="form-control"
                                     [disabled]="benifList"
                                     #bcomplementAdd="ngModel"
                                     [ngClass]="{ 'is-invalid': (bcomplementAdd.touched || formGenInfo.submitted) && bcomplementAdd.errors?.required }"
                                     required
                                     placeholder="{{ 'comp_add_1' | translate  }}">
                            </div>
                            <span class="text-danger font-col"
                                  *ngIf="(bcomplementAdd.touched || formGenInfo.submitted) && bcomplementAdd.errors?.required"
                                  translate>
                               comp_add_req_1
                       </span>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label class="font-col-label  form-control-label text-muted" for="bcompadd" translate>
                              comp_add_2
                            </label>
                            <div class="input-group">
                              <input type="text"
                                     name="bcompadd"
                                     id="bcompadd"
                                     [(ngModel)]="user.applicant.address.additional "
                                     class="form-control"
                                     [disabled]="benifList"
                                     placeholder="{{ 'comp_add_2' | translate }}">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-4">
            </div>

            <form #formGenInfo="ngForm" (ngSubmit)="formGenInfo.valid && nextCalIn(formGenInfo.value)"
                  novalidate>
              <!--NEW BENEFICIAIRE-->
              <div *ngIf="cancel" class="row">
                <div class="col-7">
                  <h6 class="text-muted mt-2 mb-2">
                    <span *ngIf="dossiers.length===0" translate>infg </span>
                    <span *ngIf="dossiers.length>0" translate>infgb </span><span
                    class="text-primary"> {{ applicant.firstName + ' ' + applicant.lastName }}</span></h6>
                </div>
                <div class="col-5">
                  <i *ngIf="dossiers.length > 0" (click)="cancelCurrentBen()" class="fa fa-trash-o mt-2 _cursor-point"
                     aria-hidden="true"></i>
                </div>
              </div>
              <div *ngIf="cancel" class="card p-3">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="prenom" translate>
                        prenom
                        <span class="text-danger font-col">
                           *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="prenom"
                               id="prenom"
                               [(ngModel)]="applicant.firstName"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (prenom.touched || formGenInfo.submitted) && prenom.errors?.required }"
                               #prenom="ngModel"
                               required
                               placeholder="{{ 'prenom' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(prenom.touched || formGenInfo.submitted) && prenom.errors?.required" translate>
                               pren_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="nom" translate>
                        nom_nec
                        <span class="text-danger font-col">
                    *
                  </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="nom"
                               id="nom"
                               [(ngModel)]="applicant.lastName"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (nom.touched || formGenInfo.submitted) && nom.errors?.required }"
                               #nom="ngModel"
                               required
                               placeholder="{{ 'nom_nec' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(nom.touched || formGenInfo.submitted) && nom.errors?.required" translate>
                               nom_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="genre" translate>
                        gen
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="genre"
                                id="genre"
                                [(ngModel)]="applicant.gender"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (genre.touched || formGenInfo.submitted) && genre.errors?.required }"
                                #genre="ngModel"
                                required>
                          <option [ngValue]="'M'" translate>men</option>
                          <option [ngValue]="'F'" translate>wom</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(genre.touched || formGenInfo.submitted) && genre.errors?.required" translate>
                               gen_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="dateNaissance" translate>
                        naiss
                        <span class="text-danger font-col">
                         *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="Date"
                               name="dateNaissance"
                               id="dateNaissance"
                               [(ngModel)]="birthInfo.day"
                               class="form-control"
                               #dateNaissance="ngModel"
                               required
                               placeholder="{{ 'naiss' | translate }}"
                               [ngClass]="{ 'is-invalid': ((dateNaissance.touched || formGenInfo.submitted) && dateNaissance.errors?.required)
                                                  || (dateNaissance.value != '' && dateNaissance.value > latest_date)}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(dateNaissance.touched || formGenInfo.submitted) && dateNaissance.errors?.required"
                            translate>
                                naiss_req
                       </span>
                      <span class="text-danger font-col"
                            *ngIf="(dateNaissance.value != '' && dateNaissance.value > latest_date)" translate>
                                            naiss_inf<br>
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="pays" translate>
                        pay_naiss
                        <span class="text-danger font-col">
                        *
                       </span>
                      </label>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="pays"
                                id="pays"
                                [(ngModel)]="birthInfo.country"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (pays.touched || formGenInfo.submitted) && pays.errors?.required }"
                                #pays="ngModel"
                                required>
                          <option [ngValue]="country.code" *ngFor="let country of countries">
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(pays.touched || formGenInfo.submitted) && pays.errors?.required" translate>
                               pay_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="city" translate>
                        city
                        <span class="text-danger font-col">
                         *
                       </span>
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="city"
                               id="city"
                               [(ngModel)]="birthInfo.city"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': (city.touched || formGenInfo.submitted) && city.errors?.required }"
                               #city="ngModel"
                               required
                               placeholder="{{ 'city' | translate }}">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(city.touched || formGenInfo.submitted) && city.errors?.required" translate>
                               city_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="ambassade" translate>
                        ambassade
                      </label>
                      <span class="text-danger font-col">
                         *
                       </span>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="ambassade"
                                id="ambassade"
                                [(ngModel)]="test"
                                [disabled]="dossiers.length > 0"
                                #ambassade="ngModel"
                                (change)="onchangeAmb($event)"
                                [ngClass]="{ 'is-invalid': (ambassade.touched || formGenInfo.submitted) && ambassade.errors?.required }"
                                required>
                          <option *ngFor="let agency of agencies" [value]="agency.reference" translate>{{agency.code}}</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(ambassade.touched || formGenInfo.submitted) && ambassade.errors?.required"
                            translate>
                                ambreq
                       </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="renewal" translate>
                        demType
                      </label>
                      <span class="text-danger font-col">
                         *
                       </span>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="renewal"
                                id="renewal"
                                [(ngModel)]="userSched.renewal"
                                #renewal="ngModel"
                                [ngClass]="{ 'is-invalid': (renewal.touched || formGenInfo.submitted) && renewal.errors?.required }"
                                required>
                          <option [ngValue]="false" translate>firstDem</option>
                          <option [ngValue]="true" translate>renewal</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(renewal.touched || formGenInfo.submitted) && renewal.errors?.required"
                            translate>
                                renouvq
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="duration" translate>
                        duration
                      </label>
                      <span class="text-danger font-col">
                         *
                       </span>
                      <div class="input-group">
                        <select class="form-control rect"
                                name="duration"
                                id="duration"
                                #duration="ngModel"
                                [(ngModel)]="userSched.duration"
                                required
                                class="form-control"
                                [ngClass]="{ 'is-invalid': (duration.touched || formGenInfo.submitted) && duration.errors?.required }">
                          <option [ngValue]="durationfive" translate>five</option>
                          <option [ngValue]="durationten" translate>ten</option>
                        </select>
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(duration.touched || formGenInfo.submitted) && duration.errors?.required"
                            translate>
                                duration_req
                       </span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label  form-control-label text-muted" for="passeportNum" translate>
                        passeportNum
                      </label>
                      <div class="input-group">
                        <input type="text"
                               name="passeportNum"
                               id="passeportNum"
                               [(ngModel)]="userSched.number"
                               class="form-control"
                               placeholder="{{ 'passeportNum' | translate }}">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label class="font-col-label form-control-label text-muted" for="cidnumber" translate>
                        cart_con
                      </label>
                      <span class="text-danger font-col">
                        *
                       </span>
                      <span (click)="showMdalCidNum()" class="fa fa-question-circle ml-1 text-muted pointer_con"
                            tooltip="{{'cidloc' | translate}}" placement="top"></span>
                      <div class="input-group">
                        <input type="text"
                               class="form-control"
                               name="cidnumber"
                               id="cidnumber"
                               [(ngModel)]="userSched.cidNumber"
                               required
                               #cidnumber="ngModel"
                               placeholder="{{ 'cart_con' | translate }}"
                               [ngClass]="{ 'is-invalid': (cidnumber.touched || formGenInfo.submitted) && cidnumber.errors?.required }">
                      </div>
                      <span class="text-danger font-col"
                            *ngIf="(cidnumber.touched || formGenInfo.submitted) && cidnumber.errors?.required"
                            translate>
                               cidNumber_req
                       </span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="dossiers.length===0">
                <h6 class="text-muted mt-4 mb-2" translate>contact</h6>
                <div class="card p-3">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="reEmail">
                          Email
                        </label>
                        <div class="input-group">
                          <input type="email"
                                 class="form-control"
                                 name="email"
                                 id="reEmail"
                                 [(ngModel)]="contact.email"
                                 disabled>
                        </div>
                      </div>
                    </div>
                    <div  *ngIf="_country.length !== 0" class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="epaysres" translate>
                          resCountry
                          <span class="text-danger font-col">
                        *
                       </span>
                        </label>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="epaysres"
                                  id="epaysres"
                                  (ngModelChange)="handleResidenceChange($event)"
                                  [(ngModel)]="address.country"
                                  [ngClass]="{ 'is-invalid': (epaysres.touched || formGenInfo.submitted) && epaysres.errors?.required }"
                                  #epaysres="ngModel"
                                  required>
                            <option [ngValue]="spainCount.code" *ngFor="let spainCount of _country">
                              <span>{{ spainCount.name }}</span>
                            </option>
                          </select>
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="(epaysres.touched || formGenInfo.submitted) && epaysres.errors?.required" translate>
                               resCountry_req
                       </span>
                      </div>
                    </div>
                    <div *ngIf="address.country" class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="telmobile" translate>
                          mobile
                          <span class="text-danger font-col" *ngIf="selectedCountry.formats?.phoneNumberFormat?.require">
                        *
                       </span>
                        </label>
                        <div class="input-group">
                          <ngx-intl-tel-input
                            #telephone="ngModel"
                            [cssClass]="'form-control'"
                            [enableAutoCountrySelect]="false"
                            [onlyCountries]="onlyCountries"
                            [customPlaceholder]="('mobile' | translate)"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="address.country"
                            [maxLength]="15"
                            [phoneValidation]="false"
                            [numberFormat]="PhoneNumberFormat.International"
                            [separateDialCode]="true"
                            name="telmobile"
                            id="telmobile"
                            [(ngModel)]="contact.mobile"
                            [ngClass]="{ 'is-invalid': (telephone.touched || formGenInfo.submitted) && hasRequiredPhone() || hasPatternError}"
                            (ngModelChange)="checkPhoneNumber($event)"
                            (countryChange)="checkPhoneNumber($event)"
                            [required] = hasRequiredPhone()
                            pattern="{{selectedCountry.formats?.phoneNumberFormat?.regex}}">
                          ></ngx-intl-tel-input>
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="(telephone.touched || formGenInfo.submitted) && hasRequiredPhone()"
                              translate>
                               mob_req
                       </span>
                        <span class="text-danger font-col"
                              *ngIf="(telephone.errors || hasPatternError) && telephone.touched">
                           <p [hidden]="!hasPatternError" translate>
                                num_inv
                           </p>
                       </span>
                      </div>
                    </div>
                    <div *ngIf="address.country" class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="telephone" translate>
                          fixe
                        </label>
                        <div class="input-group">
                          <ngx-intl-tel-input
                            #fixe="ngModel"
                            [cssClass]="'form-control'"
                            [enableAutoCountrySelect]="false"
                            [onlyCountries]="onlyCountries"
                            [customPlaceholder]="('fixe' | translate)"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="address.country"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [numberFormat]="PhoneNumberFormat.International"
                            [separateDialCode]="true"
                            name="fixe"
                            id="telephone"
                            [(ngModel)]="contact.phone"
                            [ngClass]="{ 'is-invalid': (fixe.touched || formGenInfo.submitted) && fixe.errors?.required || fixe.errors?.pattern }"
                          ></ngx-intl-tel-input>
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="fixe.errors &&(fixe.touched || fixe.dirty)">
                           <p [hidden]="fixe.errors?.pattern || fixe.untouched" translate>
                                num_inv
                           </p>
                       </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="cityres" translate>
                          resCity
                          <span class="text-danger font-col">
                         *
                       </span>
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="cityres"
                                 id="cityres"
                                 [(ngModel)]="address.city"
                                 class="form-control"
                                 [ngClass]="{ 'is-invalid': (cityres.touched || formGenInfo.submitted) && cityres.errors?.required }"
                                 #cityres="ngModel"
                                 required
                                 placeholder="{{ 'resCity' | translate }}">
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="(cityres.touched || formGenInfo.submitted) && cityres.errors?.required" translate>
                               resCity_req
                       </span>
                      </div>
                    </div>
                    <div *ngIf="address.country" class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="codePostal" translate>
                          cod_postal
                          <span class="text-danger font-col" *ngIf="selectedCountry.formats?.zipCodeFormat?.require">
                            *
                       </span>
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="codePostal"
                                 id="codePostal"
                                 [(ngModel)]="address.zipCode"
                                 class="form-control"
                                 [ngClass]="{ 'is-invalid': (codePostal.touched || formGenInfo.submitted) && codePostal.errors?.required }"
                                 #codePostal="ngModel"
                                 [required]="selectedCountry.formats?.zipCodeFormat?.require"
                                 (ngModelChange)="address.zipCode = $event.toUpperCase()"
                                 [placeholder]="getZipCodePlaceholder()"
                                 [pattern]="getZipCodePattern()">
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="(codePostal.touched || formGenInfo.submitted) && codePostal.errors?.required"
                              translate>
                               code_req
                       </span>
                        <span class="text-danger font-col"
                              *ngIf="codePostal.errors && codePostal.touched">
                           <p [hidden]="!codePostal.invalid" translate>
                                cod_postal_inv
                           </p>
                       </span>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="complementAdd" translate>
                          comp_add_1
                          <span class="text-danger font-col">
                            *
                       </span>
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="complementAdd"
                                 id="complementAdd"
                                 [(ngModel)]="address.street"
                                 class="form-control"
                                 #complementAdd="ngModel"
                                 [ngClass]="{ 'is-invalid': (complementAdd.touched || formGenInfo.submitted) && complementAdd.errors?.required }"
                                 required
                                 placeholder="{{ 'comp_add_1' | translate  }}">
                        </div>
                        <span class="text-danger font-col"
                              *ngIf="(complementAdd.touched || formGenInfo.submitted) && complementAdd.errors?.required"
                              translate>
                               comp_add_req_1
                       </span>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="compadd" translate>
                          comp_add_2
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="compadd"
                                 id="compadd"
                                 [(ngModel)]="address.additional"
                                 class="form-control"
                                 placeholder="{{ 'comp_add_2' | translate }}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" [disabled]="(!checkForm() || dossiers.length > 2) && cancel"
                      class="btn btn-lg btn-link mb-4 mt-1"
                      (click)="addBenif()">
                <i class="fa fa-user-plus mr-2" aria-hidden="true"></i>
                <span class="button-font" translate>add_benif</span>
              </button>
              <hr class="mt-4">
              <div>
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="previousVerification()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="submit"
                        class="btn btn-primary pull-right"
                        [disabled]="nextStep && myRecaptcha || !spinerCal">
                  <span translate>next</span>
                  <span [hidden]="spinerCal" class="spinner-border spinner-border-sm ml-2" role="status"
                        aria-hidden="true" translate></span>
                  <i [hidden]="!spinerCal" class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>

          <!--Calendar-->
          <div *ngIf="isCalendar">
            <app-calendar
              [nbB]="dossiers.length"
              [agency]="agency"
              (event)="calendarEvent($event)"></app-calendar>
            <hr class="mt-4">
            <div class="mt-2">
              <button type="button"
                      class="btn btn-light"
                      [hidden]="hidePrevious"
                      (click)="previousUserInf()">
                <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                <span translate>prev</span>
              </button>
              <!-- <button type="button"
                       class="btn btn-primary pull-right"
                       [disabled]="nextStep && myRecaptcha"
                       (click)="nextRecap()">
                 <span translate>next</span>
                 <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
               </button> -->
            </div>
          </div>

          <!-- recap -->
          <div *ngIf="recapitulatif">
            <div class="mt-4 mb-4">
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>infgrdv</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>
              <div class="text-center">
                <div>
                  <h6>
                    <span class="text-muted" translate>rdvdate</span>
                    <span class="text-primary ml-3">{{ dateEvent | date:'longDate': getTimeZone(): locale }}</span>
                  </h6>
                </div>
                <div class="mt-2">
                  <h6>
                    <span class="text-muted" translate>rdvhour</span>
                    <span class="text-primary ml-3">{{ hourStart | date:'shortTime': getTimeZone(): locale }} <span
                      translate>to</span> {{ hourEnd | date:'shortTime': getTimeZone(): locale }}</span>
                  </h6>
                </div>
              </div>
              <div *ngFor="let user of dossiers; let indexOfelement=index;">
                <div *ngIf="indexOfelement===0" class="row mt-4 mb-2">
                  <div class="col-xl-4">
                    <hr>
                  </div>
                  <div class="col-xl-4 text-center">
                    <h6 translate>infg</h6>
                  </div>
                  <div class="col-xl-4">
                    <hr>
                  </div>
                </div>
                <div *ngIf="indexOfelement!==0" class="row mt-4 mb-2">
                  <div class="col-xl-4">
                    <hr>
                  </div>
                  <div class="col-xl-4 text-center">
                    <h6 translate>infgb</h6>
                  </div>
                  <div class="col-xl-4">
                    <hr>
                  </div>
                </div>
                <h6 class="text-muted cursor-point mt-2 mb-2" translate>
                  inf_gen
                </h6>
                <div class="card p-3">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbprenom" translate>
                          prenom
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="_rbprenom"
                                 id="_rbprenom"
                                 [(ngModel)]="user.applicant.firstName"
                                 class="form-control"
                                 disabled
                                 placeholder="{{ 'prenom' | translate }}">
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbnom" translate>
                          nom_nec
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="_rbnom"
                                 id="_rbnom"
                                 disabled
                                 [(ngModel)]="user.applicant.lastName"
                                 class="form-control"
                                 placeholder="{{ 'nom_nec' | translate }}">
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbgenre" translate>
                          gen
                        </label>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="_rbgenre"
                                  id="_rbgenre"
                                  disabled
                                  [(ngModel)]="user.applicant.gender">
                            <option [ngValue]="'M'" translate>men</option>
                            <option [ngValue]="'F'" translate>wom</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbdateNaissance" translate>
                          naiss
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="_rbdateNaissance"
                                 id="_rbdateNaissance"
                                 [(ngModel)]="user.applicant.birthInfo.day"
                                 class="form-control"
                                 disabled
                                 placeholder="{{ 'naiss' | translate }}">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbpays" translate>
                          pay_naiss
                        </label>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="_rbpays"
                                  id="_rbpays"
                                  disabled
                                  [(ngModel)]="user.applicant.birthInfo.country">
                            <option [ngValue]="country.code" *ngFor="let country of countries">
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbcity" translate>
                          city
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="_rbcity"
                                 id="_rbcity"
                                 [(ngModel)]="user.applicant.birthInfo.city"
                                 class="form-control"
                                 disabled
                                 placeholder="{{ 'city' | translate }}">
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="rambassade" translate>
                          ambassade
                        </label>
                        <span class="text-danger font-col">
                         *
                       </span>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="rambassade"
                                  id="rambassade"
                                  [(ngModel)]="test"
                                  disabled>
                            <option *ngFor="let agency of agencies" [value]="agency.reference" translate>{{agency.code}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbrenewal" translate>
                          demType
                        </label>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="_rbrenewal"
                                  id="_rbrenewal"
                                  [(ngModel)]="user.renewal"
                                  disabled>
                            <option [ngValue]="false" translate>firstDem</option>
                            <option [ngValue]="true" translate>renewal</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbduration" translate>
                          duration
                        </label>
                        <div class="input-group">
                          <select class="form-control rect"
                                  name="_rbduration"
                                  id="_rbduration"
                                  disabled
                                  [(ngModel)]="user.duration">
                            <option [ngValue]="durationfive" translate>five</option>
                            <option [ngValue]="durationten" translate>ten</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbpasseportNum" translate>
                          passeportNum
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 name="_rbpasseportNum"
                                 id="_rbpasseportNum"
                                 disabled
                                 [(ngModel)]="user.number"
                                 class="form-control"
                                 placeholder="{{ 'passeportNum' | translate }}">
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="font-col-label  form-control-label text-muted" for="_rbcidnumber" translate>
                          cart_con
                        </label>
                        <div class="input-group">
                          <input type="text"
                                 class="form-control"
                                 name="_rbcidnumber"
                                 id="_rbcidnumber"
                                 disabled
                                 [(ngModel)]="user.cidNumber"
                                 placeholder="{{ 'cart_con' | translate }}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="indexOfelement === 0">
                  <h6 class="text-muted mt-4 mb-2" translate>contact</h6>
                  <div class="card p-3">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbreEmail">
                            Email
                          </label>
                          <div class="input-group">
                            <input type="email"
                                   class="form-control"
                                   name="_rbreEmail"
                                   id="_rbreEmail"
                                   [(ngModel)]="user.applicant.contact.email"
                                   disabled>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbtelmobile" translate>
                            mobile
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   class="form-control"
                                   name="_rbtelmobile"
                                   id="_rbtelmobile"
                                   [(ngModel)]="user.applicant.contact.mobile"
                                   disabled
                                   placeholder="{{ 'mobile' | translate }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbtelephone" translate>
                            fixe
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   class="form-control"
                                   name="_rbtelephone"
                                   id="_rbtelephone"
                                   [(ngModel)]="user.applicant.contact.phone"
                                   disabled
                                   placeholder="{{ 'fixe' | translate }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbpaysres" translate>
                            resCountry
                          </label>
                          <div class="input-group">
                            <select class="form-control rect"
                                    name="_rbpaysres"
                                    id="_rbpaysres"
                                    [(ngModel)]="user.applicant.address.country"
                                    disabled>
                              <option [ngValue]="country.code" *ngFor="let country of countries">
                                {{ country.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbcityres" translate>
                            resCity
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="_rbcityres"
                                   id="_rbcityres"
                                   disabled
                                   [(ngModel)]="user.applicant.address.city"
                                   class="form-control"
                                   placeholder="{{ 'city' | translate }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbcodePostal" translate>
                            cod_postal
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="_rbcodePostal"
                                   id="_rbcodePostal"
                                   [(ngModel)]="user.applicant.address.zipCode"
                                   class="form-control"
                                   disabled
                                   placeholder="{{ 'cod_postal' | translate}}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="_rbcomplementAdd" translate>
                            comp_add_1
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="_rbcomplementAdd"
                                   id="_rbcomplementAdd"
                                   [(ngModel)]="user.applicant.address.street"
                                   class="form-control"
                                   disabled
                                   placeholder="{{ 'comp_add_1' | translate  }}">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="font-col-label  form-control-label text-muted" for="rbcompadd" translate>
                            comp_add_2
                          </label>
                          <div class="input-group">
                            <input type="text"
                                   name="rbcompadd"
                                   id="rbcompadd"
                                   [(ngModel)]="user.applicant.address.additional "
                                   class="form-control"
                                   disabled
                                   placeholder="{{ 'comp_add_2' | translate }}">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5 mb-2">
                <div class="col-xl-4">
                  <hr>
                </div>
                <div class="col-xl-4 text-center">
                  <h6 translate>rdvliste</h6>
                </div>
                <div class="col-xl-4">
                  <hr>
                </div>
              </div>
              <div class="mt-3">
                <span class="text-danger" translate>doc</span><br>
                <span class="text-appoin" translate>doc1</span>
                <a data-toggle="tooltip" title="{{'down' | translate}}"
                   class="cursor-point fa fa-download ml-3"
                   [download]="myFileName" [href]="fileUrl"></a><br>
                <span class="text-appoin" translate>doc2</span><br>
                <span class="text-appoin" translate>doc3</span><br>
                <span class="text-appoin" translate>doc4</span><br>
                <span class="text-appoin" translate>doc5</span><br>
              </div>

              <hr class="mt-4">
              <div class="mt-2">
                <button type="button"
                        class="btn btn-light"
                        [hidden]="hidePrevious"
                        (click)="previousCalendar()">
                  <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                  <span translate>prev</span>
                </button>
                <button type="button"
                        [disabled]="!_spinner"
                        class="btn btn-primary pull-right"
                        (click)="chooseAppoin()">
                  <span [hidden]="!_spinner"><span translate>val</span>
                    <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></span>
                  <span [hidden]="_spinner" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true" translate></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--help side-->
    <div *ngIf="!isCalendar && !recapitulatif && !userInfo" class="col-xl-4 order-xl-2 mb-2 mb-xl-0">
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>help</h5>
        </div>
        <div *ngIf="_verification" class="card-body mt-3 mb-2">
          <div id="_verification-id">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  <span translate>car_email</span>
                </a>
              </div>
              <div id="_collapseTwo-ver" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body" translate>
                  email_ex
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="_collapseOne-ver"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>step_a</span><br>
                  <span translate>step_b</span><br>
                  <span translate>step_c</span><br>
                  <span translate>step_d</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cooki</span>
                </a>
              </div>
              <div id="_collapseThree-ver" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cooki_desc</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="verification" class="card-body mt-3 mb-2">
          <div id="verification-id">
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne">
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  <span translate>how_</span>
                </a>
              </div>
              <div id="collapseOne-ver"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <span translate>ver_desc</span><br>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  <span translate>cooki</span>
                </a>
              </div>
              <div id="collapseThree-ver" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cooki_desc</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>

<footer class="_footer" style="background-color: #2c292f">
  <div class="container">
    <div class="row justify-content-center custom-foo">
      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <p class="text-light">
            <img src="assets/images/logo.png"
                 class="mr-1 mb-1"
                 style="width: 43px; height: 23px"/><span class="button-font">&copy;2020 A2A Expertise</span>
          </p>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-4">
          <div>
            <p><i class="fa fa-envelope mt-1 mr-2"></i><a
              href="mailto:consulats@ambaguinee.org" style="color: #1587bd">consulats@ambaguinee.org</a></p>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white text-center">
        <div class="mt-3 pt-1">
          <a href="https://www.facebook.com/A2A-Expertise-2065223603792416/"><i
            class="fa fa-facebook fa-2x text-primary mx-3"></i></a>
          <a href="https://www.linkedin.com/company/a2a-expertise"><i class="fa fa-linkedin fa-2x text-danger mx-3"></i></a>
          <a href="https://twitter.com/"><i class="fa fa-twitter fa-2x text-info mx-3"></i></a>
          <a href="https://www.youtube.com/watch?v=l21QDxgQjSc"><i class="fa fa-youtube fa-2x text-danger mx-3"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>

<ng-template #modalHelp>
  <div class="modal-header text-muted">
    <h6 class="modal-title m-auto" translate>help</h6>
  </div>
  <div class="modal-body">
    <div *ngIf="userInfo" class="mt-3 mb-2">
      <div id="general_infos-id">
        <div class="card">
          <div class="card-header button-font">
            <a class="card-link"
               data-toggle="collapse"
               (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
               href="#collapseOne">
              <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
              <span translate>how_</span>
            </a>
          </div>
          <div id="step4-inf"
               class="collapse"
               [ngClass]="{'show' : col_show_1}"
               data-parent="#accordion">
            <div class="card-body font-card-body">
              <span translate>step4_how</span><br><br>
              <span translate>step4_how_</span><br>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-1">
        <div class="card-header">
          <a class="collapsed card-link button-font"
             data-toggle="collapse"
             href="#collapseTwo"
             (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;">
            <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
            <span translate>car_password</span>
          </a>
        </div>
        <div id="_collapseTwo-ver" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
          <div class="card-body font-card-body" translate>
            car_pawd_desc
          </div>
        </div>
      </div>
      <div class="card mt-1">
        <div class="card-header">
          <a class="collapsed card-link button-font"
             data-toggle="collapse"
             href="#collapseThree"
             (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
            <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
            <span translate>cookies</span>
          </a>
        </div>
        <div id="collapseThree-inf" class="collapse"
             [ngClass]="{'show' : col_show_3}"
             data-parent="#accordion">
          <div class="card-body font-card-body">
            <p translate>cook_det</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isCalendar" class="mt-3 mb-2">
      <div id="cal-id">
        <div class="card">
          <div class="card-header button-font">
            <a class="card-link"
               data-toggle="collapse"
               (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
               href="#collapseOne">
              <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
              <span translate>how_</span>
            </a>
          </div>
          <div id="steinf"
               class="collapse"
               [ngClass]="{'show' : col_show_1}"
               data-parent="#accordion">
            <div class="card-body font-card-body">
              <span translate>stepcal</span><br><br>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-1">
        <div class="card-header">
          <a class="collapsed card-link button-font"
             data-toggle="collapse"
             href="#collapseThree"
             (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
            <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
            <span translate>cookies</span>
          </a>
        </div>
        <div id="collapscook" class="collapse"
             [ngClass]="{'show' : col_show_3}"
             data-parent="#accordion">
          <div class="card-body font-card-body">
            <p translate>cook_det</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="recapitulatif" class="mt-3 mb-2">
      <div id="rec-id">
        <div class="card">
          <div class="card-header button-font">
            <a class="card-link"
               data-toggle="collapse"
               (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
               href="#collapseOne">
              <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
              <span translate>how_</span>
            </a>
          </div>
          <div id="_recap"
               class="collapse"
               [ngClass]="{'show' : col_show_1}"
               data-parent="#accordion">
            <div class="card-body font-card-body">
              <span translate>recaphelp</span><br><br>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-1">
        <div class="card-header">
          <a class="collapsed card-link button-font"
             data-toggle="collapse"
             href="#collapseThree"
             (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;">
            <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
            <span translate>cookies</span>
          </a>
        </div>
        <div id="collapsinf" class="collapse"
             [ngClass]="{'show' : col_show_3}"
             data-parent="#accordion">
          <div class="card-body font-card-body">
            <p translate>cook_det</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="ml-auto mr-2">
        <button class="btn btn-primary btn-sm btn-block"
                (click)="modalRef.hide()" translate>
          close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<div bsModal #errorModal="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-danger m-auto">
          <i class="fa fa-exclamation-triangle text-danger mr-2"></i>
          <span translate>errmessages</span>
        </h6>
      </div>
      <div class="modal-body">
        <h6 class="m-auto pt-3 pb-3">
          <span>{{ messageError }}</span>
        </h6><br>
        <p class="p-3" style="background: #e5e5e5; border-radius: 4px">
          <span class="text-center" style="font-size: 14px" translate>carname</span><br>
          <span class="pt-1 _font-col text-danger" translate>care1</span><br>
          <span class="_font-col text-danger" translate>care2</span><br>
          <span class="_font-col text-danger" translate>care3</span><br>
          <span class="_font-col text-danger" translate>care4</span><br>
        </p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideModal();" translate>
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #modalCond="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-muted m-auto" translate>cond_gen</h6>
      </div>
      <div class="modal-body">
        <p class="font-card-body" translate>con1</p>
        <p class="font-card-body" translate>con2</p>
        <p class="font-card-body" translate>con3</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideConModal();" translate>
              acc
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #modalCidNum="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-muted m-auto" translate>cidloc</h6>
      </div>
      <div class="modal-body">
        <p translate class="font-card-body text-center">location</p>
        <div class="text-center">
          <img class="img_id m-2" src="assets/images/cartcon.png">
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hidemMdalCidNum();" translate>
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---
<div bsModal #errorUserModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-danger m-auto" translate>attention</h6>
      </div>
      <div class="modal-body">
        <h6 class="m-auto pt-3 pb-3">
          <span translate>cart1</span> <span class="text-primary">{{ userSched.cidNumber }}</span>
          <span translate>cart2</span> <span
          class="text-primary">{{ applicant.lastName }} {{ applicant.firstName }}</span>
          <span translate>cart3</span> <span class="text-primary">{{ birthInfo.day | date: 'dd/MM/yyyy' }}</span>
          <span translate>cart4</span> <span class="text-primary">{{ birthInfo.city }}</span>
        </h6>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideUserModal();" translate>
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
--->
<div bsModal #confirmModal="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static'}"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-danger m-auto">
          <i class="fa fa-exclamation-triangle text-danger mr-2"></i>
          <span translate>confheader</span>
        </h6>
      </div>
      <div class="modal-body">
        <p class="m-auto pt-3 pb-3">
          <span translate>confmessage1</span><br><br>
          <span translate>confirmessage2</span><br><br>
          <span translate>confirmessage3</span>
        </p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideConfirmModal();" translate>
              acc
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</html>
