import {Applicant} from "./applicant";

export class UserSched {
  constructor(
    public isCollapsed?: boolean,
    public duration?: number,
    public number?: string,
    public renewal?: boolean,
    public cidNumber?: string,
    public applicant?: Applicant
  ) {
    this.isCollapsed = true;
    this.duration = null;
    this.number = '';
    this.renewal = null;
    this.cidNumber = '';
    this.applicant = new Applicant();
  }
}
