export class UserInfos {
  constructor(
    public event?: string,
    public agency?: string,
    public dossiers?: any[]
  ) {
    this.event = '';
    this.agency = '';
    this.dossiers = [];
  }
}
