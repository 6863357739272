import {Subject} from "./subject";

export class Verification {
  constructor(
    public type?: string,
    public token?: string,
    public subject?: Subject
  ) {
    this.type = '';
    this.token = '';
    this.subject = new Subject();
  }
}
