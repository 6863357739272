import {Address} from "./address";
import {BirthInfo} from "./birthInfo";
import {Contact} from "./contact";

export class Applicant {
  constructor(
    public lastName?: string,
    public firstName?: string,
    public gender?: string,
    public address?: Address,
    public birthInfo?: BirthInfo,
    public contact?: Contact
  ) {
    this.lastName = '';
    this.firstName = '';
    this.gender = '';
    this.address = new Address();
    this.birthInfo = new BirthInfo();
    this.contact = new Contact();
  }
}
