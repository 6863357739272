import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {addMonths, parse} from "date-fns";
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {Subscription} from "rxjs";
import {AppointmentService} from "../../services/appointment.service";
import {DatePipe} from "@angular/common";
import {Event} from "../../model/event";
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {LangService} from "../../../shared/services/lang.service";
import {Router} from "@angular/router";
import {CustomDateFormatter} from "../../../shared/services/custom-date-formatter.provider";
import {subWeeks, startOfMonth, endOfMonth, addWeeks} from 'date-fns';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    }
  ],
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnDestroy {
  @ViewChild('errorCalModal', {static: false}) errorCalModal: ModalDirective;
  @Input() nbB;
  @Input() agency: string;
  @Output() event = new EventEmitter<any>();
  @ViewChild('emailModal', {static: false}) emailModal: ModalDirective;
  activeDayIsOpen: boolean;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  date: Date = null;
  modalRef: BsModalRef;
  viewDate: Date = new Date();
  colors: any = {};
  _events: any[] = [];
  events: CalendarEvent[] = [];
  isDataAvailable = false;
  locale: string = this.langService.selectedLang;
  subscription: Subscription;
  private idEvent: any;
  spinner: any = true;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  private dateEvent: any;
  private hourStart: string;
  private hourEnd: string;
  private nbp: any;
  private agenda: any;
  private simpleErrorMessage: string;

  constructor(private appointmentService: AppointmentService,
              private modalService: BsModalService,
              private router: Router,
              private langService: LangService) {
  }

  ngOnInit(): void {
    this.activeDayIsOpen = true;
    this.getSchedules(this.agency, new DatePipe('en-US').transform(startOfMonth(new Date()), 'dd/MM/yyyy'),
      new DatePipe('en-US').transform(endOfMonth(new Date()), 'dd/MM/yyyy'));
    registerLocaleData(localeFr, 'fr');
  //console.log(this.view);
  }

  getSchedules(agency, startDate, endDate) {
    this.isDataAvailable = false;
    this._events = [];
    this.subscription = this.appointmentService.getScheduleInfos(agency, startDate, endDate).subscribe(
      (response: any) => {
        this.agenda = response.data.agenda;
        this._events = response.data.events;
      //console.log('events => ', response.data);
      },
      (error) => {
      //console.log('error sendCode => ', error);
        this.isDataAvailable = true;
        this.simpleErrorMessage = 'noCalendar';
      },
      () => {
      //console.log('this._events => *', this._events);
        this.events = [];
        this._events.forEach(event => {
          let _event;
          let percent;
          let books;
          let time;
          let timeSm;
          _event = new Event();
          books = event.threshold - event.bookings;
          percent = event.status === 'BUSY' ? 100 : (event.bookings / event.threshold) * 100;
          _event.color = this.getColor(percent);
          _event.start = new Date(this.formatDate(event.start));
          _event.end = new Date(this.formatDate(event.end));
          _event.id = event.reference;
          _event.meta = event.status;
          time = new DatePipe('en-US').transform(_event.start, 'HH:mm') + '-' + new DatePipe('en-US').transform(_event.end, 'HH:mm');
          timeSm = new DatePipe('en-US').transform(_event.start, 'HH') + '-' + new DatePipe('en-US').transform(_event.end, 'HH');
          _event.title = time + ' , ' + (books <= 0 || event.status === 'BUSY' ? 0 : books).toString() + " place(s) dispo.";
          _event.titleSm = timeSm;
          _event.nbp = (books <= 0 || event.status === 'BUSY' ? 0 : books).toString() + ' Place(s) ';
          _event.nbBenif = (books <= 0 || event.status === 'BUSY' ? 0 : books);
          this.events.push(_event);
        });
        this.isDataAvailable = true;
      }
    );
  }

  _getSchedules(agency, agenda, startDate, endDate) {
    this.isDataAvailable = false;
    this._events = [];
    this.subscription = this.appointmentService._getScheduleInfos(agency, agenda, startDate, endDate).subscribe(
      (response: any) => {
        this._events = response.data;
      },
      (error) => {
      //console.log('error sendCode => ', error);
      },
      () => {
      //console.log('this._events => *', this._events);
        this.events = [];
        this._events.forEach(event => {
          let _event;
          let percent;
          let books;
          let time;
          let timeSm;
          _event = new Event();
          books = event.threshold - event.bookings;
          percent = event.status === 'BUSY' ? 100 : (event.bookings / event.threshold) * 100;
          _event.color = this.getColor(percent);
          _event.start = new Date(this.formatDate(event.start));
          _event.end = new Date(this.formatDate(event.end));
          _event.id = event.reference;
          _event.meta = event.status;
          time = new DatePipe('en-US').transform(_event.start, 'HH:mm') + '-' + new DatePipe('en-US').transform(_event.end, 'HH:mm');
          timeSm = new DatePipe('en-US').transform(_event.start, 'HH') + '-' + new DatePipe('en-US').transform(_event.end, 'HH');
          _event.title = time + ' , ' + (books <= 0 || event.status === 'BUSY' ? 0 : books).toString() + " place(s) dispo.";
          _event.titleSm = timeSm;
          _event.nbp = (books <= 0 || event.status === 'BUSY' ? 0 : books).toString() + ' Place(s) ';
          _event.nbBenif = (books <= 0 || event.status === 'BUSY' ? 0 : books);
          this.events.push(_event);
        });
        this.isDataAvailable = true;
      }
    );
  }

  openModal(template: TemplateRef<any>, event) {
  //console.log(event);
    this.idEvent = event.id;
    this.dateEvent = event.start;
    this.hourStart = event.start;
    this.hourEnd = event.end;
    this.nbp = event.nbp;
    if (event.meta === 'FREE' && this.nbB <= event.nbBenif) {
      this.modalRef = this.modalService.show(template);
    } else {
      this.errorCalModal.show();
    }
  }

  hideErrorCalModal(): void {
    this.errorCalModal.hide();
  }

  public getSortedEvents(events: CalendarEvent[]) {
    return events.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  formatDate(inDate) {
    this.date = parse(inDate, "d/M/yyyy HH:mm", new Date());
    return new DatePipe('en-US').transform(this.date, 'yyyy-MM-ddTHH:mm:ss');
  }

  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getColor(percent) {
    if (percent >= 0 && percent < 20) {
      return {
        primary: '#006400',
        secondary: '#006400',
      };
    } else if (percent >= 20 && percent < 50) {
      return {
        primary: '#FFD700',
        secondary: '#FFD700',
      };
    } else if (percent >= 50 && percent < 70) {
      return {
        primary: '#FF7F50',
        secondary: '#FF7F50',
      };
    } else if (percent >= 70 && percent < 100) {
      return {
        primary: '#FF6347',
        secondary: '#FF6347',
      };
    } else if (percent >= 100) {
      return {
        primary: '#EC2121',
        secondary: '#EC2121',
      };
    }
  }

  closeOpenMonthViewDay(viewDate) {
    this._getSchedules(this.agency, this.agenda.reference, new DatePipe('en-US').transform(startOfMonth(viewDate), 'dd/MM/yyyy'),
      new DatePipe('en-US').transform(endOfMonth(viewDate), 'dd/MM/yyyy'));
  }

  chooseAppoin() {
    this.event.emit({'idEvent': this.idEvent, 'dateEvent': this.dateEvent, 'hourStart': this.hourStart, 'hourEnd': this.hourEnd});
    this.spinner = true;
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  getEventToday() {
  //console.log('today event');
  }
}
