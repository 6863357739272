<div class="back_col">
<header #topLogin>
  <nav class="navbar navbar-expand-lg navbar-light Header">
    <h5 class="my-0 resp-mar font-weight-normal">
      <i class="fa fa-long-arrow-left cursor-point"
         data-toggle="tooltip" title="Acceuil"
         (click)="onRedirectHome()"></i></h5>
    <img class="ekaidi_img" src="assets/images/ekaidi_max.png">
    <button class="navbar-toggler" type="button" (click)="show=!show">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse text-center" [ngClass]="{'show' : show}">
      <ul class="nav navbar-nav ml-auto">
        <div class="nav-link my-2 my-md-0 mr-md-3">
          <button class="btn btn-danger mb-1 respon" (click)="onRedirectRegister()">
            <i class="fa fa-user-plus mr-2" aria-hidden="true"></i>
            <span class="button-font" translate>label_btn_sign</span>
          </button>
        </div>
      </ul>
    </div>
  </nav>
</header>
<body  class="marg back_col">
<!---<p class="font-col" translate>path_</p>--->
<div>
  <!--auth initial developpé-->
  <div class="row">
    <div class="col-xl-8 order-xl-1 mb-2 mb-xl-0">
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>log_conn</h5>
        </div>
        <div class="card-body mt-3 mb-2">
          <form #form="ngForm" (ngSubmit)="form.valid && onLogin(form.value)"
                [appMatchPassword]="['newPassword', 'confNewPassword']" novalidate>
            <div class="form-group">
              <label class="font-col-label form-control-label text-muted" for="username" translate>
                username
                <span class="text-danger font-col">
                        *
                       </span>
              </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="rect input-group-text">
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </div>
                </div>
                <input type="text"
                       name="username"
                       id="username"
                       [(ngModel)]="credentials.username"
                       class="form-control"
                       [ngClass]="{ 'is-invalid': (name.touched || form.submitted) && name.errors?.required }"
                       #name="ngModel"
                       placeholder="{{ 'username' | translate }}"
                       required>
              </div>
              <span class="text-danger font-col"
                    *ngIf="(name.touched || form.submitted) && name.errors?.required" translate>
                          err_username
                            </span>
            </div>
            <div class="form-group  mt-4">
              <label class="font-col-label form-control-label text-muted" for="username" translate>
                password
                <span class="text-danger font-col">
                        *
                       </span>
              </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="rect input-group-text">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                  </div>
                </div>
                <input type="password"
                       name="password"
                       id="password"
                       [(ngModel)]="credentials.password"
                       class="form-control"
                       [ngClass]="{ 'is-invalid': (password.touched || form.submitted) && password.errors?.required }"
                       #password="ngModel"
                       placeholder="{{ 'password' | translate }}"
                       appPasswordPattern
                       required>
              </div>
              <span class="text-danger font-col"
                    *ngIf="(password.touched || form.submitted) && password.errors?.required" translate>
                   err_password
            </span>
              <span class="text-danger font-col" *ngIf="password.touched && password.errors?.invalidPassword" translate>
                   err_password_
                </span>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <button type="submit"
                        [disabled]="!myRecaptcha"
                        class="btn btn-primary btn-block">
                  <span translate>connexion</span>
                  <i class="ml-3 fa fa-sign-in" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <hr class="mt-4">
            <div class="row mt-4 justify-content-center">
              <div>
                <ngx-recaptcha2 #captchaElem
                                [(ngModel)]="ekaidiRecaptcha"
                                name="ekaidiRecaptcha"
                                (reset)="handleReset()"
                                (expire)="handleExpire()"
                                [siteKey]="siteKey"
                                (load)="handleLoad()"
                                (success)="handleSuccess($event)"
                                [useGlobalDomain]="false"
                                [hl]="langue">
                </ngx-recaptcha2>
              </div>
            </div>
          </form>
          <hr class="mt-4">
          <p class="mt-4 btn-link text-center cursor-point" translate>
            forgetpassword
          </p>
        </div>
      </div>
    </div>

    <div class="col-xl-4 order-xl-2 mb-2 mb-xl-0">
      <div class="card shadow">
        <div class="card-header bg-header text-center">
          <h5 class="font-weight-normal text-muted" translate>help</h5>
        </div>
        <div class="card-body mt-3 mb-2">
          <div id="accordion">
            <div class="card">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseTwo"
                   (click)="col_show_2=!col_show_2; col_show_3=false; col_show_1=false;" translate>
                  <span class="mr-2">{{ col_show_2 ? '-' : '+'}}</span>
                  pass_car
                </a>
              </div>
              <div id="collapseTwo" class="collapse" [ngClass]="{'show' : col_show_2}" data-parent="#accordion">
                <div class="card-body font-card-body" translate>
                  pass_car_txt<br>pass_car_txt_

                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header">
                <a class="collapsed card-link button-font"
                   data-toggle="collapse"
                   href="#collapseThree"
                   (click)="col_show_3=!col_show_3; col_show_2=false; col_show_1=false;" translate>
                  <span class="mr-2">{{ col_show_3 ? '-' : '+'}}</span>
                  cookies
                </a>
              </div>
              <div id="collapseThree" class="collapse"
                   [ngClass]="{'show' : col_show_3}"
                   data-parent="#accordion">
                <div class="card-body font-card-body">
                  <p translate>cook_det</p>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-header button-font">
                <a class="card-link"
                   data-toggle="collapse"
                   (click)="col_show_1=!col_show_1; col_show_2=false; col_show_3=false;"
                   href="#collapseOne" translate>
                  <span class="mr-2">{{ col_show_1 ? '-' : '+'}}</span>
                  otherhelp
                </a>
              </div>
              <div id="collapseOne"
                   class="collapse"
                   [ngClass]="{'show' : col_show_1}"
                   data-parent="#accordion">
                <div class="card-body font-card-body" translate>
                  otherhelp_
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</body>


<ng-template #accExist>
  <div class="modal-body modal-style">
    <div class="row">
      <div class="col-lg-10">
        <h6 class="modal-title text-center cus_col" translate>succIns</h6>
      </div>
      <div class="col-lg-2">
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide();langService.succSub=false;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
</div>
