import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/components/home/home.component';
import {LoginComponent} from './login/components/login/login.component';
import {RegisterComponent} from './register/components/register/register.component';
import {P500Component} from './error/500.component';
import {P404Component} from './error/404.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {PasswordPatternDirective} from "./shared/directives/password-pattern.directive";
import {ValidateUserNameDirective} from "./shared/directives/validate-user-name.directive";
import {MatchPasswordDirective} from "./shared/directives/match-password.directive";
import {FaqComponent} from './faq/faq.component';
import {ModalModule} from "ngx-bootstrap/modal";
import {DatePipe} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxCaptchaModule} from "ngx-captcha";
import {AlertModule} from "ngx-bootstrap/alert";
import {AppointmentComponent} from './appointment/components/appointment/appointment.component';
import {CalendarComponent} from './appointment/components/calendar/calendar.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {SortByPipe} from "./shared/pipes/sort-by.pipe";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SortByPipe,
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    P404Component,
    P500Component,
    PasswordPatternDirective,
    MatchPasswordDirective,
    ValidateUserNameDirective,
    FaqComponent,
    AppointmentComponent,
    CalendarComponent
  ],
  imports: [
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    NgxCaptchaModule,
    TranslateModule,
    AppRoutingModule,
    BrowserModule,
    RouterModule.forRoot([]),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    ImageCropperModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AlertModule,
    NgxIntlTelInputModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
