<div class="text-center mt-5 mb-5" *ngIf="!isDataAvailable">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!--<div *ngIf="isDataAvailable && events.length ==0" class="text-center mt-5 mb-5 alert alert-danger" role="alert">
  <span translate>noCalendar</span>
</div> -->
<div *ngIf="isDataAvailable" class="card mt-4">
  <div class="row text-center mt-3 mb-2">
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay(viewDate)" translate>
          prev
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          (viewDateChange)="closeOpenMonthViewDay(viewDate)"
          [(viewDate)]="viewDate" translate>
          Today
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay(viewDate)" translate>
          next
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>
        {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn | uppercase }}
      </h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month" translate>
          month
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week" translate>
          week
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day" translate>
          day
        </div>
      </div>
    </div>
  </div>

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="text-center m-1" *ngFor="let event of getSortedEvents(day.events)">
      <span class="text-white appointment d-none d-sm-block"
            [ngStyle]="{'background-color': event?.color?.primary}"
            (click)="openModal(confirmModal, event)">{{ event.title }}</span>
    </div>
    <div class="text-center m-1" *ngFor="let event of day.events">
      <span class="text-white appointment d-block d-sm-none"
            [ngStyle]="{'background-color': event?.color?.primary}"
            (click)="openModal(confirmModal, event)">{{ event.titleSm }}</span>
    </div>
  </ng-template>
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [cellTemplate]="customCellTemplate">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [weekendDays]="weekendDays"
      [events]="events"
      [dayStartHour]="8"
      [dayEndHour]="17"
      (eventClicked)="openModal(confirmModal, $event.event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [locale]="locale"
      [viewDate]="viewDate"
      [events]="events"
      [dayStartHour]="8"
      [dayEndHour]="17"
      (eventClicked)="openModal(confirmModal, $event.event)">
    </mwl-calendar-day-view>
  </div>
</div>
<ng-template class="modal-dialog-centered" #confirmModal>
  <div class="modal-header text-muted">
    <h6 class="modal-title m-auto" translate>confirm</h6>
  </div>
  <div class="modal-body">
    <div class="row d-sm-none">
      <div class="col-6">
        <h6 class="pull-right">
          <span class="text-muted" translate>nbplace</span>
        </h6>
      </div>
      <div class="col-6">
        <h6 class="pull-left">
          <span class="text-primary">{{ nbp }}</span>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h6 class="pull-right">
          <span class="text-muted" translate>rdvdate</span>
        </h6>
      </div>
      <div class="col-6">
        <h6 class="pull-left">
          <span class="text-primary">{{ dateEvent | date:'longDate': getTimeZone(): locale }}</span>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h6 class="pull-right">
          <span class="text-muted" translate>rdvhour</span>
        </h6>
      </div>
      <div class="col-6">
        <h6 class="pull-left">
          <span class="text-primary">{{ hourStart | date:'shortTime': getTimeZone(): locale }} <span
            translate>to</span> {{ hourEnd | date:'shortTime': getTimeZone(): locale }}</span>
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-light btn-sm btn-block"
                (click)="modalRef.hide()" translate>
          handle
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary btn-sm btn-block"
                (click)="spinner=false; chooseAppoin(); modalRef.hide()">
          <span [hidden]="!spinner" translate>val</span>
          <span [hidden]="spinner" class="spinner-border spinner-border-sm ml-2" role="status"
                aria-hidden="true" translate></span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<div bsModal #errorCalModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="text-danger m-auto" translate>attention</h6>
      </div>
      <div class="modal-body">
        <h6 class="m-auto pt-3 pb-3">
          <span translate>insuffPlace</span>
        </h6>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="ml-auto mr-2">
            <button class="btn btn-primary btn-sm btn-block"
                    (click)="hideErrorCalModal();" translate>
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
