export class Event {
  constructor(
    public start?: string,
    public end?: string,
    public color?: string,
    public title?: string,
    public meta?: string,
    public titleSm?: string,
    public nbp?: string,
    public nbBenif?: number
  ) {
    this.start = '';
    this.end = '';
    this.color = '';
    this.title = '';
    this.meta = '';
    this.titleSm = '';
    this.nbp = '';
    this.nbBenif = 0;
  }
}
