import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {LangService} from "../shared/services/lang.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit  {
  col_show_1: Boolean = false;
  col_show_2: Boolean = false;
  col_show_3: Boolean = false;
  col_show_4: Boolean = false;
  col_show_5: Boolean = false;

  constructor(private router: Router,
              private langService: LangService) { }

  ngOnInit(): void {
  }

  onRedirectHome() {
    this.router.navigateByUrl('home');
  }

  openNewTabPdf() {
    if (this.langService.selectedLang) {
      window.open('assets/guide_utilisation.pdf', '_blank');
    } else {
      window.open('assets/guide_utilisation_en.pdf', '_blank');
    }
  }

  scrollToTopOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {
    this.scrollToTopOnInit();
  }

}
