import {Injectable, OnInit} from '@angular/core';
import {CrudService} from "../../shared/crud.service";
import {Observable} from "rxjs";
import {API_URLS} from '../../app-config/api.url.config';
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService implements CrudService, OnInit {
  private httpParams;

  constructor(private httpClient: HttpClient) {
  }

  add(item): Observable<any> {
    return undefined;
  }

  delete(id: number): Observable<any> {
    return undefined;
  }

  getAll(): Observable<any> {
    return undefined;
  }

  ngOnInit(): void {
  }

  update(item): Observable<any> {
    return undefined;
  }

  verifyEmail(type, contact, discriminator, recaptcha) {
    this.createHttpParam(type, contact, discriminator, recaptcha);
    return this.httpClient.get(API_URLS.API_URLS_APPOINT, {params: this.httpParams});
  }

  verifyUser(gender, lastName, firstName, number, process, birthDay) {
    this.createVerifHttpParam(gender, lastName, firstName, number, process, birthDay);
    return this.httpClient.get(API_URLS.API_URLS_CHECK_USER, {params: this.httpParams});
  }

  validateCode(validation): Observable<any> {
    return  this.httpClient.put(API_URLS.API_URLS_APPOINT, validation);
  }

  generateCode(contact) {
    return  this.httpClient.post(API_URLS.API_URLS_APPOINT, contact);
  }

  contactData(data) {
    return  this.httpClient.post(API_URLS.API_URLS_APPOINT_PASSPORT, data);
  }

  getScheduleInfos(agency, startDate, endDate) {
    return this.httpClient.get(API_URLS.API_URLS_APPOINT_SCHEDULE + '?agency=' + agency + '&startDate=' + startDate + '&endDate=' + endDate);
  }

  _getScheduleInfos(agency, agenda, startDate, endDate) {
    return this.httpClient.get(API_URLS.API_URLS_APPOINT_SCHEDULES + '?agency=' + agency + '&agenda=' + agenda + '&from=' + startDate + '&to=' + endDate);
  }

  getAgency() {
    return this.httpClient.get(API_URLS.API_URLS_AGENCY);
  }

  addCode(a, b) {
    return null;
  }

  createHttpParam(type, contact, discriminator, recaptcha) {
    this.httpParams = new HttpParams();
    this.httpParams = this.httpParams.append('type', type);
    this.httpParams = this.httpParams.append('contact', contact);
    this.httpParams = this.httpParams.append('discriminator', discriminator);
    this.httpParams = this.httpParams.append('recaptcha', recaptcha);
  }

  createVerifHttpParam(gender, lastName, firstName, number, process, birthDay) {
    this.httpParams = new HttpParams();
    this.httpParams = this.httpParams.append('gender', gender);
    this.httpParams = this.httpParams.append('lastName', lastName);
    this.httpParams = this.httpParams.append('firstName', firstName);
    this.httpParams = this.httpParams.append('number', number);
    this.httpParams = this.httpParams.append('process', process);
    this.httpParams = this.httpParams.append('birthDay', birthDay);
  }
}
