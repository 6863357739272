import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {P404Component} from './error/404.component';
import {P500Component} from './error/500.component';
import {LoginComponent} from './login/components/login/login.component';
import {RegisterComponent} from './register/components/register/register.component';
import {HomeComponent} from './home/components/home/home.component';
import {FaqComponent} from "./faq/faq.component";
import {AppointmentComponent} from "./appointment/components/appointment/appointment.component";


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'appointment',
    component: AppointmentComponent
  },
  {
    path: '404',
    component: P404Component
  },
  {
    path: '500',
    component: P500Component
  },
  {path: '**', component: P404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
