export class Address {
  constructor(
    public city?: string,
    public zipCode?: string,
    public country?: string,
    public additional?: string,
    public street?: string
  ) {
    this.city = '';
    this.zipCode = '';
    this.country = '';
    this.additional = '';
    this.street = '';
  }
}
