import { environment } from "../../environments/environment";

const EKAIDI_URL = environment.EKAIDI_URL;
const MAGIC_LINK_URL = environment.APPOINTMENT_URL;

export const API_URLS  = {
  API_URLS_SEND: EKAIDI_URL + '/parties-api/api/onboarding/emails/',
  API_URLS_VERIFY: EKAIDI_URL + '/parties-api/api/onboarding/',
  API_URLS_VALIDATE: EKAIDI_URL + '/parties-api/api/onboarding/emails/validation',
  API_URLS_SENDDATA: EKAIDI_URL + '/parties-api/api/onboarding',
  API_URLS_APPOINT: MAGIC_LINK_URL + '/magic-link/api/links',
  API_URLS_APPOINT_PASSPORT: MAGIC_LINK_URL + '/passport-api/api/buckets',
  API_URLS_APPOINT_SCHEDULE: MAGIC_LINK_URL + '/passport-api/api/agendas',
  API_URLS_APPOINT_SCHEDULES: MAGIC_LINK_URL + '/agenda-api/api/events',
  API_URLS_MERCANET: MAGIC_LINK_URL + '/passport-api/api/mercanet',
  API_URLS_CHECK_USER: EKAIDI_URL + '/cases-api/api/products',
  API_URLS_AGENCY: EKAIDI_URL + '/agency-api/api/agencies',
  API_URLS_CONTACTS: EKAIDI_URL + '/parties-api/api/contacts'
};

