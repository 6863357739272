import {AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from "@angular/common";
import {CustomvalidationService} from "../../../shared/services/customvalidation.service";
import {TranslateService} from '@ngx-translate/core';
import {LangService} from "../../../shared/services/lang.service";
import {environment} from "../../../../environments/environment";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('emailModal', { static: false }) emailModal: ModalDirective;
  show: Boolean = false;
  windowScrolled: boolean;
  show_condition = this.customService.show_condition;
  selectedLang: string = this.langService.selectedLang;
  private emailSend: any = false;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private customService: CustomvalidationService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private langService: LangService) {
  }

  ngOnInit() {

  }

  getParametres() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.emailSend = params['isMessageSend'];
      if (this.emailSend) {
        this.showEmailModal();
      }
    });
  }

  showEmailModal(): void {
    this.emailModal.show();
  }

  hideEmailModal(): void {
    this.emailModal.hide();
    this.router.navigateByUrl('home');
  }

  ngAfterViewInit(): void {
    this.scrollToTopOnInit();
    this.getParametres();
  }

  scrollToTopOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }


  onRedirectLogin() {
    window.open('/account', '_self');
  }

  onRedirectRegister() {
    this.router.navigateByUrl('/register');
  }


  onRedirectToFaq() {
    this.router.navigateByUrl('/faq');
  }

  onRedirectToAppointment() {
    this.router.navigateByUrl('/appointment');
  }

  navigateSection(target): void {
    this.show = false;
    target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  scrollToTop(target) {
    target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  openNewTabPdf() {
    if (this.langService.selectedLang === 'FR') {
      window.open('https://resources.ambaguinee.org/conditions.pdf', '_blank');
    } else {
      window.open('https://resources.ambaguinee.org/conditions.pdf', '_blank');
    }
  }

  hideCondition() {
    this.customService.show_condition = false;
    this.show_condition = false;
  }

  changerLangueFR() {
    this.langService.onChangeLanguage('fr');
    this.selectedLang = 'FR';
    this.show = !this.show;
  }

  changerLangueEN() {
    this.langService.onChangeLanguage('en');
    this.selectedLang = 'EN';
    this.show = !this.show;
  }
}
