import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {interval, Observable, Subscription, timer} from "rxjs";
import {RegisterService} from "../../services/register.service";
import {siteKey} from '../../../app-config/app.recap';
import {LangService} from "../../../shared/services/lang.service";
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import CountryJson from '../../../../assets/data/country.json';
import GuineeCityJson from '../../../../assets/data/guineecity.json';
import {Country} from '../../../_models/country';
import {GuineeCity} from '../../../_models/guineecity';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {AppointmentService} from "../../../appointment/services/appointment.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private router: Router,
              private datePipe: DatePipe,
              private registerService: RegisterService,
              private appointmentService: AppointmentService,
              private langService: LangService,
              private modalService: BsModalService) {
    this.currentDate = new Date();
    this.latest_date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
  }

  @ViewChild('accExist') accExist;
  @ViewChild('modalErr') modalErr;
  @ViewChild('modalConfirmResidence', {static: true}) modalConfirmResidence: ModalDirective;
  @ViewChild('telephone') telephone;
  @ViewChild('communeResidance') communeResidance;
  @ViewChild('codePostal') codePostal;
  @ViewChild('paysresi') paysresi;
  @ViewChild('rue') rue;
  errMessage: string;
  accountError: string;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };

  city_state_cont: Boolean = false;
  currentDate = new Date(Date.now());
  latest_date;
  langue = this.langService.selectedLang;
  siteKey: any = siteKey;
  subscription: Subscription;
  spinner: Boolean = true;
  btn_send: Boolean = false;
  showContentPopup: Boolean = true;
  uploadFileOK: Boolean = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  respErrCode: Boolean = false;

  imgeHeight;
  imgeWidth;
  show: Boolean = false;
  col_show_1: Boolean = false;
  col_show_2: Boolean = false;
  col_show_3: Boolean = false;

  countries: Country[] = CountryJson;
  gn_city: GuineeCity[] = GuineeCityJson;
  _city: any[] = [];
  _unique_state = [];

  user_informations = {
    username: '',
    newPassword: '',
    confNewPassword: '',
    email: '',
    code: '',
    prenom: '',
    nom: '',
    usageNom: '',
    situation: '',
    genre: '',
    dateNaissance: '',
    pays: '',
    prefReg: '',
    commune: '',
    profession: '',
    rue: '',
    complementAdd: '',
    codePostal: '',
    communeResidance: '',
    departementR: '',
    rePays: '',
    rePaysName: '',
    telephone: null,
    docName: '',
    docCode: '',
    docNum: '',
    dateDeliv: '',
    dateExp: '',
    fileBase64: '',
    croppedImage64: '',
    photo_name2: 'PHOTO'
  };

  _validation = {
    token: '',
    userId: '',
    otpCode: '',
    username: '',
    password: ''
  };

  user_data = {
    gender: '',
    process: '',
    firstName: '',
    lastName: '',
    maidenName: '',
    password: '',
    username: '',
    profession: '',
    contact: {
      email: '',
      mobile: '',
      type: ''
    },
    address: {
      city: '',
      state: '',
      country: '',
      streetName: '',
      additional: '',
      zipCode: ''
    },
    birthInfo: {
      day: '',
      state: '',
      country: '',
      city: ''
    },
    maritalStatus: '',
    resources: [
      {
        code: '',
        name: '',
        number: '',
        content: '',
        issueDate: '',
        expiryDate: '',
        mime: '',
        fileType: ''
      },
      {
        code: '',
        type: '',
        name: '',
        content: '',
        mime: '',
        fileType: ''
      }]
  };

  selectedOption = {
    situation: '',
    genre: '',
    profession: ''
  };

  resCountries: any[] = [];

  ekaidiRecaptcha: any = null;
  myRecaptcha: Boolean = false;
  max = 7;
  dynamic = 1;
  type: string;
  value = 1;
  title = 't1';
  nextStep: Boolean = true;
  hidePrevious: Boolean = false;
  justResultSucc: Boolean = true;
  justErr: Boolean = false;

  justPhotoSucc: Boolean = true;
  justPhotoErr: Boolean = false;

  justifAccept: Boolean = true;

  /* Etapes d'inscription */
  _verification: Boolean = true;
  verification: Boolean = false;
  connexion_param: Boolean = false;
  general_infos: Boolean = false;
  contact_adress: Boolean = false;
  justificatifs: Boolean = false;
  recap: Boolean = false;
  i = 1;
  /**********************/
  col_7 = 'col-xl-7';
  col_12 = 'col-xl-10';
  prev_hand = false;
  email_req = false;

  private timer: Observable<any>;
  showAlert = false;
  profileCreated = false;
  count = 5;

  confirmResidenceName = '';

  selectedAddress = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  onlyCountries: any[] = [];
  selectedCountry: Country;
  phoneValidation = false;
  hasErrors = false;

  ngOnInit() {
    if (this.value === 1) {
      this.hidePrevious = true;
    }
    const arr = [];
    this.gn_city.forEach(function (val) {
      arr.push(val.state);
    });

    this._unique_state = arr.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    // this.updateResCountry();
  }

  public setTimer(time) {
    this.showAlert = true;
    this.timer = timer(time);
    this.subscription = this.timer.subscribe(() => {
      this.showAlert = false;
    });
  }


  openModalAccExist(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onRedirectHome() {
    this.router.navigateByUrl('home');
  }

  onRedirectlogin() {
    window.open('/account', '_self');
  }

  onReceiveCode(value: any) {
    this.myRecaptcha = false;
    this.spinner = false;
    this.prev_hand = false;

    this.subscription = this.registerService.addCode(this.user_informations.email, this.ekaidiRecaptcha).subscribe(
      (resp) => {
        if (resp.status === 'KO' && resp.error === this.user_informations.email) {
          // email déja validé
          this.btn_send = false;
          this.incrementStep();
          this.incrementStep();
          this.title = 't3';
          this.hidePrevious = false;
          this.connexion_param = true;
          this._verification = false;
          this.prev_hand = true;
          this.accountError = 'email_valid';
          this.openModalAccExist(this.accExist);
          this.nextStep = false;
        } else if (resp.status === 'KO' && resp.error !== this.user_informations.email) {
        //console.log(resp);
          // compte existe déja
          this.btn_send = false;
          this.accountError = 'account_exist';
          this.openModalAccExist(this.accExist);
          this.nextStep = true;
        } else if (resp.status === 'OK') {
          this.btn_send = true;
          this.nextStep = false;
          this.nextVerification2();
        }
      },
      (error) => {
      //console.log('An error occurred in nextVerification2() ! : ' + error);
      },
      () => {
        this.spinner = true;
        // console.log('Observable complete!');
      }
    );
  }

  nextVerification2() {
    if (this.prev_hand) {
      this.incrementStep();
      this.incrementStep();
      this.title = 't3';
      this.hidePrevious = false;
      this.connexion_param = true;
      this._verification = false;
    } else {
      this.setTimer(5000);
      this.incrementStep();
      this.title = 't2';
      this.hidePrevious = false;
      this._verification = false;
      this.verification = true;
      this.prev_hand = false;
    }
  }

  nextConnexionParam(value) {
    this.spinner = false;
    this._validation.token = this.ekaidiRecaptcha;
    this._validation.otpCode = this.user_informations.code;
    this._validation.password = this.user_informations.code;
    this._validation.username = this.user_informations.email;

    this.subscription = this.registerService.validateCode(this._validation).subscribe(
      (resp) => {
        if (resp.status === 'OK') {
          this.incrementStep();
          this.title = 't3';
          this.hidePrevious = false;
          this.verification = false;
          this.connexion_param = true;
        } else {
          this.respErrCode = true;
        }
      },
      (error) => {
      //console.log('An error occurred in nextConnexionParam() ! : ' + error);
      },
      () => {
        // console.log('Observable complete!');
        this.spinner = true;
      }
    );
  }

  onVerifyUsername() {
    if (this.user_informations.username !== '') {
      this.subscription = this.registerService.verifyUsername(this.user_informations.username).subscribe((value) => {
          if (value.data) {
            this.respErrCode = true;
          } else {
            this.respErrCode = false;
          }
        },
        (error) => {
        //console.log('An error occurred in onVerifyUsername() ! : ' + error);
        });
    }
  }

  nextGeneralInfo(value) {
    if (this.user_informations.username !== '') {
      this.subscription = this.registerService.verifyUsername(this.user_informations.username).subscribe((value) => {
          if (value.data) {
            this.respErrCode = true;
          } else {
            this.respErrCode = false;
            this.incrementStep();
            this.title = 't4';
            this.connexion_param = false;
            this.general_infos = true;
          }
        },
        (error) => {
        //console.log('An error occurred in onVerifyUsername() ! : ' + error);
        });
    }
  }

  nextContactAdress(value) {
    this.incrementStep();
    this.title = 't5';
    this.general_infos = false;
    this.contact_adress = true;
    this.updateSitu();
    this.updateGenre();
    this.updateProfession();
    // update residence countries
    if (this.resCountries.length === 0) {
      this.updateResCountry();
    }
  }

  updateResCountry() {
    this.subscription = this.appointmentService.getAgency().subscribe((resp: any) => {
      //console.log(resp);
        resp.data.forEach( agency => {
          this.resCountries = this.resCountries.concat(this.countries.filter(country =>
            country.ambaRattachement && agency.code === country.ambaRattachement));
        });
        this.resCountries.forEach(country =>
          this.onlyCountries.push(Object.values(this.CountryISO).find(val => val === country.code.toLowerCase())));
      },
      (error) => {
      //console.log('error sendCode => ', error);
      },
      () => {
      //console.log('complete => *');
      }
    );
  }

  nextJustificatifs(value) {
    this.incrementStep();
    this.title = 't6';
    this.contact_adress = false;
    this.justificatifs = true;
  }

  nextRecap() {
    if (this.user_informations.docCode === 'SGN00029') {
      this.user_informations.docName = 'passport';
      this.user_data.resources[0].name = 'Passeport';
    } else if (this.user_informations.docCode === 'SGN00028') {
      this.user_informations.docName = 'idcart';
      this.user_data.resources[0].name = 'Carte d\'identité';
    } else if (this.user_informations.docCode === 'SGN00024') {
      this.user_informations.docName = 'titr_sej';
      this.user_data.resources[0].name = 'Titre de séjour';
    } else if (this.user_informations.docCode === 'SGN00023') {
      this.user_informations.docName = 'rec';
      this.user_data.resources[0].name = 'Récépissé de titre de séjour';
    } else if (this.user_informations.docCode === 'SGN00030') {
      this.user_informations.docName = 'cart_con';
      this.user_data.resources[0].name = 'Carte consulaire';
    }

    this.incrementStep();
    this.title = 't7';
    this.justificatifs = false;
    this.recap = true;
  }

  incrementStep() {
    if (this.value !== this.max) {
      this.value = this.value + 1;
      this.dynamic = this.value;
      this.i++;
    }
  }

  _previousVerification() {
    this.decrementStep();
    this._verification = true;
    this.verification = false;
    this.title = 't1';
    this.hidePrevious = true;
    this.myRecaptcha = false;
    this.respErrCode = false;
  }


  previousVerification() {
    if (this.prev_hand) {
      this.decrementStep();
      this.decrementStep();
      this.title = 't1';
      this.hidePrevious = true;
      this.connexion_param = false;
      this._verification = true;
    } else {
      this.decrementStep();
      this.verification = true;
      this.connexion_param = false;
      this.title = 't2';
    }
  }

  previousConnParam() {
    this.decrementStep();
    this.connexion_param = true;
    this.general_infos = false;
    this.title = 't3';
  }

  previousGenInfo() {
    this.decrementStep();
    this.general_infos = true;
    this.contact_adress = false;
    this.title = 't4';
  }

  previousContactAdress() {
    this.decrementStep();
    this.contact_adress = true;
    this.justificatifs = false;
    this.title = 't5';
  }

  previousJustificatif() {
    this.decrementStep();
    this.justificatifs = true;
    this.recap = false;
    this.title = 't6';
    this.nextStep = false;
    this.justifAccept = true;
  }


  decrementStep() {
    if (this.value > 1) {
      this.value = this.value - 1;
      this.dynamic = this.value;
      this.type = '';
      this.i--;
    }
  }


  uploadFile($event) {
    if ((($event.target.files[0].size / 1000) > 1000) && $event.target.files[0] !== undefined) {
      this.uploadFileOK = false;
    } else {
      this.uploadFileOK = true;
      this.readThis($event.target);
    }
  }

  getFileType(mimeType) {
    if (mimeType === 'application/pdf') {
      return 'PDF';
    } else if (mimeType === 'image/jpeg') {
      return 'JPEG';
    } else if (mimeType === 'image/png') {
      return 'PNG';
    } else if (mimeType === 'image/gif') {
      return 'GIF';
    } else if (mimeType === 'application/zip') {
      return 'ZIP';
    } else if (mimeType === 'application/msword') {
      return 'DOC';
    } else if (mimeType === 'image/bmp') {
      return 'BMP';
    }
  }

  readThis(inputValue: any): void {
    try {
      if (inputValue.files[0] !== undefined) {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.user_informations.fileBase64 = myReader.result.toString();
        };
        myReader.readAsDataURL(file);
      }
    } catch (e) {
    //console.log('Erreur de téléchargement de l\'image : ', e);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if ((event.target.files[0].size / 1000) > 1000) {
      this.showContentPopup = false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imgeWidth = event.width;
    this.imgeHeight = event.height;
    this.user_informations.croppedImage64 = this.croppedImage;
  }

  base64Mime(encoded) {
    let result = null;

    if (typeof encoded !== 'string') {
      return result;
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  checkCheckbox(event) {
    this.justifAccept = !this.justifAccept;
  }

  openNewTabPdf() {
    window.open('https://resources.ambaguinee.org/conditions.pdf', '_blank');
  }

  handleExpire() {
    this.myRecaptcha = false;
    this.btn_send = false;
  }

  handleLoad() {
  }

  handleSuccess($event: string) {
    this.ekaidiRecaptcha = $event;
    this.myRecaptcha = true;
    this.btn_send = false;
    if (this.user_informations.email === '') {
      this.email_req = true;
    }
  }

  handleReset() {
    this.myRecaptcha = true;
  }

  openModalERR(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onSendData() {
    this.spinner = true;
    this.onGenData();
    const mimeType0 = this.user_data.resources[0].mime;
    const fileType0 = this.user_data.resources[0].fileType;
    const mimeType1 = this.user_data.resources[1].mime;
    const fileType1 = this.user_data.resources[1].fileType;
    if (isNotNullOrUndefined(mimeType0) && isNotNullOrUndefined(fileType0) &&
      isNotNullOrUndefined(mimeType1) && isNotNullOrUndefined(fileType1)) {
      this.subscription = this.registerService.add(this.user_data).subscribe(
        (value) => {
          if (value.status === 'OK') {
            this.spinner = false;
            this.onGenData();
            this.profileCreated = true;
            this.timer = timer(5000);
            this.subscription = interval(1000).subscribe((val) => {
              --this.count;
            });
            this.subscription = this.timer.subscribe(() => {
              window.open('/account', '_self');
              this.langService.succSub = true;
            });
          } else {
            this.errMessage = 'ERROR: ' + value.error;
            this.openModalERR(this.modalErr);
          }
        },
        (error) => {
        //console.log('An error occurred in onSendData() ! : ' + error.toString());
          this.spinner = false;
        },
        () => {
          // console.log('Observable complete!');
          this.spinner = false;
        }
      );
    } else {
    //console.log('An error occurred in onSendData() ! : ' + "Empty file content or mimeType");
      this.spinner = false;
      this.errMessage = 'ERROR: Empty file content or mime';
      this.openModalERR(this.modalErr);
    }
  }

  onGenData() {
    this.user_data.gender = this.user_informations.genre;
    this.user_data.process = 'pgn0018';
    this.user_data.firstName = this.user_informations.prenom;
    this.user_data.lastName = this.user_informations.nom;
    this.user_data.maidenName = this.user_informations.usageNom;
    this.user_data.password = this.user_informations.newPassword;
    this.user_data.username = this.user_informations.username;
    this.user_data.profession = this.user_informations.profession;

    this.user_data.contact.email = this.user_informations.email;
    this.user_data.contact.mobile = typeof this.user_informations.telephone === 'string' ?
      this.user_informations.telephone : this.user_informations.telephone.e164Number;
    this.user_data.contact.type = 'C';

    this.user_data.address.city = this.user_informations.communeResidance;
    this.user_data.address.state = this.user_informations.departementR;
    this.user_data.address.country = this.user_informations.rePays;
    this.user_data.address.streetName = this.user_informations.rue;
    this.user_data.address.additional = this.user_informations.complementAdd;
    this.user_data.address.zipCode = this.user_informations.codePostal;


    this.user_data.birthInfo.state = this.user_informations.prefReg;
    this.user_data.birthInfo.city = this.user_informations.commune;

    this.user_data.maritalStatus = this.user_informations.situation;

    this.user_data.resources[0].code = this.user_informations.docCode;
    this.user_data.resources[0].number = this.user_informations.docNum;
    this.user_data.resources[0].content = this.user_informations.fileBase64;
    this.user_data.resources[0].mime = this.base64Mime(this.user_informations.fileBase64);
    this.user_data.resources[0].fileType = this.getFileType(this.user_data.resources[0].mime);

    this.user_data.resources[0].issueDate = new DatePipe('en-US').transform(this.user_informations.dateDeliv, 'dd/MM/yyyy');
    this.user_data.resources[0].expiryDate = new DatePipe('en-US').transform(this.user_informations.dateExp, 'dd/MM/yyyy');
    this.user_data.birthInfo.day = new DatePipe('en-US').transform(this.user_informations.dateNaissance, 'dd/MM/yyyy');


    this.user_data.resources[1].code = 'SGN00005';
    this.user_data.resources[1].type = 'PHOTO';
    this.user_data.resources[1].name = 'Photo d\'identité';
    this.user_data.resources[1].content = this.user_informations.croppedImage64;
    this.user_data.resources[1].mime = this.base64Mime(this.user_informations.croppedImage64);
    this.user_data.resources[1].fileType = this.getFileType(this.user_data.resources[1].mime);

    const pays = this.user_informations.pays;
    let _pays;
    this.countries.forEach(function (countr) {
      if (countr.name === pays) {
        _pays = countr.code;
      }
    });
    this.user_data.birthInfo.country = _pays;
  }


  updateSitu() {
    if (this.user_informations.situation === 'SINGLE') {
      this.selectedOption.situation = 'cel';
    } else if (this.user_informations.situation === 'MARRIED') {
      this.selectedOption.situation = 'mar';
    } else if (this.user_informations.situation === 'WIDOWER') {
      this.selectedOption.situation = 'veu';
    } else if (this.user_informations.situation === 'CONCUBINAGE') {
      this.selectedOption.situation = 'conc';
    } else if (this.user_informations.situation === 'DIVORCED') {
      this.selectedOption.situation = 'div';
    }
  }

  updateGenre() {
    if (this.user_informations.genre === 'F') {
      this.selectedOption.genre = 'wom';
    } else if (this.user_informations.genre === 'M') {
      this.selectedOption.genre = 'men';
    }
  }

  updateProfession() {
    if (this.user_informations.profession === 'FARMER') {
      this.selectedOption.profession = 'prof_1';
    } else if (this.user_informations.profession === 'HEAD_OF_COMPANY') {
      this.selectedOption.profession = 'prof_2';
    } else if (this.user_informations.profession === 'MERCHANTS') {
      this.selectedOption.profession = 'prof_3';
    } else if (this.user_informations.profession === 'FREELANCE') {
      this.selectedOption.profession = 'prof_4';
    } else if (this.user_informations.profession === 'SENIOR_PUBLIC_SECTOR') {
      this.selectedOption.profession = 'prof_5';
    } else if (this.user_informations.profession === 'HIGHS_SKILLED_EXECUTIVE') {
      this.selectedOption.profession = 'prof_6';
    } else if (this.user_informations.profession === 'PROFESSOR_SCIENTIST') {
      this.selectedOption.profession = 'prof_7';
    } else if (this.user_informations.profession === 'INFORMATION_ENTERTAINMENT_ARTIST') {
      this.selectedOption.profession = 'prof_8';
    } else if (this.user_informations.profession === 'ENGINEER') {
      this.selectedOption.profession = 'prof_9';
    } else if (this.user_informations.profession === 'PROFESSOR_TEACHER') {
      this.selectedOption.profession = 'prof_10';
    } else if (this.user_informations.profession === 'CLERGY_RELIGIOUS') {
      this.selectedOption.profession = 'prof_11';
    } else if (this.user_informations.profession === 'TECHNICAL_OFFICER') {
      this.selectedOption.profession = 'prof_12';
    } else if (this.user_informations.profession === 'FOREMAN') {
      this.selectedOption.profession = 'prof_13';
    } else if (this.user_informations.profession === 'POLICE_MILITARY') {
      this.selectedOption.profession = 'prof_14';
    } else if (this.user_informations.profession === 'CORPORATE_EMPLOYEE') {
      this.selectedOption.profession = 'prof_15';
    } else if (this.user_informations.profession === 'HEALTH_SOCIAL_ASSIST') {
      this.selectedOption.profession = 'prof_16';
    } else if (this.user_informations.profession === 'DOMESTIC_HOUSEHOLD_SERVICES') {
      this.selectedOption.profession = 'prof_17';
    } else if (this.user_informations.profession === 'SKILLED_WORKER') {
      this.selectedOption.profession = 'prof_18';
    } else if (this.user_informations.profession === 'UNSKILLED_WORKER') {
      this.selectedOption.profession = 'prof_19';
    } else if (this.user_informations.profession === 'DRIVER') {
      this.selectedOption.profession = 'prof_20';
    } else if (this.user_informations.profession === 'RETIRED') {
      this.selectedOption.profession = 'prof_21';
    } else if (this.user_informations.profession === 'UNEMPLOYED') {
      this.selectedOption.profession = 'prof_22';
    } else if (this.user_informations.profession === 'PUPILS_STUDENTS') {
      this.selectedOption.profession = 'prof_23';
    } else if (this.user_informations.profession === 'ANOTHER') {
      this.selectedOption.profession = 'prof_24';
    } else if (this.user_informations.profession === 'HOUSE_WIFE_HUSBAND') {
      this.selectedOption.profession = 'prof_25';
    }
  }

  onCountChange(country: any) {
    if (country === 'GUINEE') {
      this.city_state_cont = true;
    } else {
      this.city_state_cont = false;
      this.user_informations.commune = '';
      this.user_informations.prefReg = '';
    }
  }

  stateChange(state: any) {
    const cit: any [] = [];
    this.gn_city.forEach(function (value) {
      if (value.state === state) {
        cit.push(value.city);
      }
    });
    this._city = cit;
  }

  onchange(value: any) {
    if (this.user_informations.genre !== 'F' || this.user_informations.situation !== 'MARIED') {
      this.user_informations.usageNom = '';
    }
  }

  scrollToTopOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {
    this.scrollToTopOnInit();
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  showModalConfirmResidence() {
    this.modalConfirmResidence.show();
  }

  hideModalConfirmResidence() {
    this.modalConfirmResidence.hide();
  }

  handleResidenceChange(event: any) {
    this.selectedCountry = this.resCountries.filter(country => country.code === event)[0];
    this.selectedAddress = false;
    if (this.selectedCountry) {
      this.confirmResidenceName = this.selectedCountry.name;
      this.selectedAddress = true;
      this.user_informations.rePaysName = this.selectedCountry.name;
      this.user_informations.codePostal = '';
      this.user_informations.communeResidance = '';
      this.user_informations.prefReg = '';
      this.user_informations.telephone = null;
    }
    this.showModalConfirmResidence();
  }

  checkPhoneNumber(event: any) {
    if (event) {
      const phoneNumber = event;
      if (this.selectedCountry.formats?.phoneNumberFormat?.require && this.selectedCountry.formats?.phoneNumberFormat?.regex) {
        let phNumber = phoneNumber.number.toString().replaceAll(' ', '');
        this.hasErrors = !phNumber.match(this.selectedCountry.formats.phoneNumberFormat.regex);
      } else if (this.selectedCountry.formats?.phoneNumberFormat?.require) {
        this.subscription = this.registerService.checkMobile(this.selectedCountry.code, phoneNumber.e164Number).subscribe((value) => {
            this.hasErrors = !value.data;
          },
          (error) => {
          //console.log('An error occurred in onCheckMobile() ! : ' + error);
          },
          () => {
          //console.log('complete => *');
          });
      } else {
        this.hasErrors = false;
      }
    }
  }

  hasRequiredErrors() {
    if (!this.user_informations.telephone && this.selectedCountry.formats?.phoneNumberFormat?.require) {
      return true;
    }
    return false;
  }

}
